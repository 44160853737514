import { App as AntdApp, ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import enUS from 'antd/lib/locale/en_US';
import nlNL from 'antd/lib/locale/nl_NL';
import { Provider } from 'mobx-react';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import abpUserConfigurationService from 'src/services/abpUserConfigurationService';
import { themeConfig } from 'src/theme/themeConfig';
import Utils from 'src/utils/utils';
import App from './App';
import registerRelay42 from './registerRelay42';
import initializeStores from './stores/storeInitializer';
import './theme/global.less';

Utils.setLocalization();

const stores = initializeStores();

const storeContext = React.createContext(stores);
export const useStores = () => React.useContext(storeContext);

abpUserConfigurationService.getAll().then((data) => {
    Utils.extend(true, abp, data.data.result);
    abp.clock.provider = Utils.getCurrentClockProvider(data.data.result.clock.provider);

    moment.locale(abp.localization.currentLanguage.name);

    if (abp.clock.provider.supportsMultipleTimezone) {
        moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
    }

    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(
        <Provider {...stores}>
            <BrowserRouter>
                <ConfigProvider
                    locale={abp.localization.currentLanguage.name === 'nl' ? nlNL : enUS}
                    theme={themeConfig}
                >
                    <AntdApp>
                        <App />
                    </AntdApp>
                </ConfigProvider>
            </BrowserRouter>
        </Provider>
    );

    registerRelay42();
});
