import React, { Suspense } from 'react';
import Loading from './../Loading/index';

export const LazyComponent = (component: any, props: any = {}) => {
    const Component = React.lazy(component);
    return (
        <Suspense fallback={<Loading />}>
            <Component {...props} />
        </Suspense>
    );
};
