export enum PushNotificationCategory{
    StartSurvey = 0,
    CompleteSurvey = 1,
    PlaceOrder = 2,
    SpendRemainingBudget = 3,
    CompleteSurveyAfterSkip = 4,
    PlaceOrderAfterSkip = 5,
    RedoSurvey = 6,
    GiveFeedback = 7,
    GiveFeedbackRecurring = 8,
}
