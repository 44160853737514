import { action, makeObservable, observable, runInAction } from 'mobx';

import CategoryService from 'src/services/category/categoryService';
import { CreateOrUpdateCategoryCrossCorrelationInput } from 'src/services/category/dto/createOrUpdateCategoryCrossCorrelationInput';
import type { CreateOrUpdateCategoryInput } from 'src/services/category/dto/createOrUpdateCategoryInput';
import { GetCategoryOutput } from 'src/services/category/dto/getCategoryOutput';
import type { UpdateCategoryCrossCorrelationsInput } from 'src/services/category/dto/updateCategoryCrossCorrelationsInput';
import type { UpdateCategoryInput } from 'src/services/category/dto/updateCategoryInput';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';

class CategoryStore {
    @observable categories: PagedResultDto<GetCategoryOutput>;
    @observable editCategory: CreateOrUpdateCategoryInput;
    @observable crossCorrelations: CreateOrUpdateCategoryCrossCorrelationInput[];

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createCategoryInput: CreateOrUpdateCategoryInput) {
        const result = await CategoryService.create(createCategoryInput);
        runInAction(() => {
            this.categories.items.push(result);
        });
    }

    @action
    async update(updateCategoryInput: UpdateCategoryInput) {
        const result = await CategoryService.update(updateCategoryInput);
        runInAction(() => {
            this.categories.items = this.categories.items.map((x: GetCategoryOutput) => {
                if (x.id === updateCategoryInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto) {
        await CategoryService.delete(entityDto);
        runInAction(() => {
            this.categories.items = this.categories.items.filter((x: GetCategoryOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto) {
        const result = await CategoryService.get(entityDto);
        runInAction(() => {
            this.editCategory = result;
            this.crossCorrelations = result.crossCorrelations;
        });
    }

    @action
    async updateCrossCorrelations(updateCrossCorrelations: UpdateCategoryCrossCorrelationsInput) {
        return CategoryService.updateCrossCorrelations(updateCrossCorrelations);
    }

    @action
    createCategory() {
        this.editCategory = {
            localizations: [
                {
                    id: undefined,
                    parentId: 0,
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    description: '',
                },
            ],
            order: 0,
            themeId: 0,
            id: 0,
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await CategoryService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.categories = result;
        });
    }
}

export default CategoryStore;
