import { action, makeObservable } from 'mobx';
import apiUserService from 'src/services/apiUser/apiUserService';
import type CreateApiUserInput from 'src/services/apiUser/dto/createApiUserInput';
import type RevokeApiUserInput from 'src/services/apiUser/dto/revokeApiUserInput';
import CreateApiUserOutput from 'src/services/apiUser/dto/createApiUserOutput';

class ApiUserStore {
    constructor() {
        makeObservable(this);
    }

    @action
    async createBusinessUnitApiUser(input: CreateApiUserInput): Promise<CreateApiUserOutput> {
        return await apiUserService.createForBusinessUnit(input);
    }

    @action
    async revokeBusinessUnitApiUser(input: RevokeApiUserInput): Promise<any> {
        return await apiUserService.revokeForBusinessUnit(input);
    }
}

export default ApiUserStore;
