import { action, makeObservable, observable, runInAction } from 'mobx';

import * as pagedResultDto from 'src/services/dto/pagedResultDto';
import { TaxWageSurveyDto } from 'src/services/taxWageSurvey/dto/taxWageSurveyDto';
import TaxWageSurveyService from 'src/services/taxWageSurvey/taxWageSurveyService';

class TaxWageSurveyStore {
    @observable surveys: pagedResultDto.PagedResultDto<TaxWageSurveyDto>;

    constructor() {
        makeObservable(this);
    }

    @action
    async getAll() {
        const result = await TaxWageSurveyService.getAll();
        runInAction(() => {
            this.surveys = result;
        });
    }
}

export default TaxWageSurveyStore;
