import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateEmployerGroupInput } from 'src/services/employerGroup/dto/createOrUpdateEmployerGroupInput';
import { EmployerGroupOutput } from 'src/services/employerGroup/dto/employerGroupOutput';
import type { GetAllEmployerGroupsInput } from 'src/services/employerGroup/dto/getAllEmployerGroupsInput';
import EmployerGroupService from 'src/services/employerGroup/employerGroupService';
import { sessionInstance } from 'src/stores/sessionStore';

class EmployerGroupStore {
    @observable employerGroups: PagedResultDto<EmployerGroupOutput>;
    @observable editEmployerGroup: CreateOrUpdateEmployerGroupInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createGroupInput: CreateOrUpdateEmployerGroupInput) {
        const result = await EmployerGroupService.create(createGroupInput);
        runInAction(() => {
            this.employerGroups.items.push(result);
        });
        this.updateSession();
    }

    @action
    async update(updateGroupInput: CreateOrUpdateEmployerGroupInput) {
        const result = await EmployerGroupService.update(updateGroupInput);
        runInAction(() => {
            this.employerGroups.items = this.employerGroups.items.map((x: EmployerGroupOutput) => {
                if (x.id === updateGroupInput.id) x = result;
                return x;
            });
        });
        this.updateSession();
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await EmployerGroupService.delete(entityDto);
        runInAction(() => {
            this.employerGroups.items = this.employerGroups.items.filter(
                (x: EmployerGroupOutput) => x.id !== entityDto.id
            );
        });
        this.updateSession();
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await EmployerGroupService.get(entityDto);
        runInAction(() => {
            this.editEmployerGroup = { employerId: undefined, ...result };
        });
    }

    @action
    createEmployerGroup() {
        this.editEmployerGroup = {
            id: '',
            employerId: '',
            name: '',
        };
    }

    @action
    async getAll(getAllRequest: GetAllEmployerGroupsInput) {
        const result = await EmployerGroupService.getAll(getAllRequest);
        runInAction(() => {
            this.employerGroups = result;
        });
        this.updateSession();
    }

    updateSession() {
        if (sessionInstance?.currentLogin?.employer) {
            sessionInstance.currentLogin.employer.groups = this.employerGroups.items;
        }
    }
}

export default EmployerGroupStore;
