import http from '../httpService';
import EmployerFiltersDto from './dto/employerFiltersDto';

class EmployerFilterService {
    public async getFilters(): Promise<EmployerFiltersDto> {
        const result = await http.get('api/services/app/Employer/GetEmployerFilters');
        return result.data.result;
    }
}

export default new EmployerFilterService();
