import { Guid } from 'guid-typescript';
import { EntityDto } from '../dto/entityDto';
import { PagedRequestDto } from '../dto/pagedRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import http from '../httpService';
import { FaqCreateOrUpdateCategoryInput, GetFaqCategoryOutput, GetFaqCategoryWithQuestionsOutput } from './dto/faqCategoryDto';
import { FaqCreateOrUpdateQuestionInput, GetFaqQuestionOutput } from './dto/faqQuestionDto';

class FaqService {
    public async getAllCategories(request: PagedRequestDto): Promise<PagedResultDto<GetFaqCategoryOutput>> {
        const result = await http.get(`/api/services/app/FaqCategory/GetAll`, { params: request });
        return result.data.result;
    }

    public async getCategoryById(entityDto: EntityDto): Promise<GetFaqCategoryOutput> {
        const result = await http.get('api/services/app/FaqCategory/Get', { params: entityDto });
        return result.data.result;
    }

    public async createCategory(createCategoryInput: FaqCreateOrUpdateCategoryInput) {
        const result = await http.post('api/services/app/FaqCategory/Create', createCategoryInput);
        return result.data.result;
    }

    public async updateCategory(updateCategoryInput: FaqCreateOrUpdateCategoryInput) {
        const result = await http.put('api/services/app/FaqCategory/Update', updateCategoryInput);
        return result.data.result;
    }

    public async deleteCategory(entityDto: EntityDto) {
        const result = await http.delete('api/services/app/FaqCategory/Delete', { params: entityDto });
        return result.data;
    }

    public async getAllQuestions(request: PagedRequestDto): Promise<PagedResultDto<GetFaqQuestionOutput>> {
        const result = await http.get(`/api/services/app/FaqQuestion/GetAll`, { params: request });
        return result.data.result;
    }

    public async getQuestionById(entityDto: EntityDto<Guid>): Promise<GetFaqQuestionOutput> {
        const result = await http.get('api/services/app/FaqQuestion/Get', { params: entityDto });
        return result.data.result;
    }

    public async createQuestion(createCategoryInput: FaqCreateOrUpdateQuestionInput) {
        const result = await http.post('api/services/app/FaqQuestion/Create', createCategoryInput);
        return result.data.result;
    }

    public async updateQuestion(updateCategoryInput: FaqCreateOrUpdateQuestionInput) {
        const result = await http.put('api/services/app/FaqQuestion/Update', updateCategoryInput);
        return result.data.result;
    }

    public async deleteQuestion(entityDto: EntityDto<Guid>) {
        const result = await http.delete('api/services/app/FaqQuestion/Delete', { params: entityDto });
        return result.data;
    }

    public async getAllFaq(category: string = ""): Promise<PagedResultDto<GetFaqCategoryWithQuestionsOutput>> {
        const result = await http.get(`/api/services/app/FaqCategory/GetAllFaq`, { params: {category: category} });
        return result.data.result;
    }

    public async getAllFaqAnonymous(category: string = ""): Promise<PagedResultDto<GetFaqCategoryWithQuestionsOutput>> {
        const result = await http.get(`/api/services/app/FaqCategory/GetAllFaqAnonymous`, { params: {category: category} });
        return result.data.result;
    }
}

export default new FaqService();
