import { action, makeObservable, observable, runInAction } from 'mobx';

import { PaymentCondition } from 'src/models/Billing/PaymentCondition';
import { Branche } from 'src/models/Branche/Branche';
import { ShopModule } from 'src/models/Modules/ShopModule';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { GetAllEmployeesInput } from 'src/services/userProfiles/dto/GetAllEmployeesInput';
import type { GetAllEmployerAccountsInput } from 'src/services/userProfiles/dto/GetAllEmployerAccountsInput';
import type { GetAllEmployersInput } from 'src/services/userProfiles/dto/GetAllEmployersInput';
import type { UpdateTotalBudgetDto } from 'src/services/userProfiles/dto/UpdateTotalBudgetDto';
import type { CreateOrUpdateEmployeeInput } from 'src/services/userProfiles/dto/createOrUpdateEmployeeInput';
import type { CreateOrUpdateEmployerAccountInput } from 'src/services/userProfiles/dto/createOrUpdateEmployerAccountInput';
import type {
    CreateOrUpdateEmployerInput,
    DuplicateEmployerInput,
} from 'src/services/userProfiles/dto/createOrUpdateEmployerInput';
import { GetEmployeeOutput } from 'src/services/userProfiles/dto/getEmployeeOutput';
import { GetEmployerAccountOutput } from 'src/services/userProfiles/dto/getEmployerAccountOutput';
import { GetEmployerOutput } from 'src/services/userProfiles/dto/getEmployerOutput';
import userProfilesService from 'src/services/userProfiles/userProfilesService';
import type { EmployerBudgetFeaturesDto } from '../services/session/dto/employerBudgetFeaturesDto';
import type { SendActivationMailInput } from '../services/userProfiles/dto/SendActivationMailInput';
import type { StatisticsOutput } from '../services/userProfiles/dto/StatisticsOutput';
import { EmployeeActivationTokenDto } from '../services/userProfiles/dto/employeeActivationTokenDto';
import type EmployerStatisticsInput from '../services/userProfiles/dto/employerStatisticsInput';

class UserProfileStore {
    @observable employees: PagedResultDto<GetEmployeeOutput>;
    @observable employers: PagedResultDto<GetEmployerOutput>;
    @observable employerAccounts: PagedResultDto<GetEmployerAccountOutput>;
    @observable editEmployee: CreateOrUpdateEmployeeInput;
    @observable editEmployer: CreateOrUpdateEmployerInput;
    @observable editEmployerBudgetFeatures: EmployerBudgetFeaturesDto;
    @observable editEmployerAccount: CreateOrUpdateEmployerAccountInput;
    @observable statistics: StatisticsOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async createEmployee(createUserInput: CreateOrUpdateEmployeeInput) {
        const result = await userProfilesService.createEmployee(createUserInput);
        runInAction(() => {
            this.employees.items.push(result);
        });
    }

    @action
    async createEmployer(createUserInput: CreateOrUpdateEmployerInput) {
        const result = await userProfilesService.createEmployer(createUserInput);
        runInAction(() => {
            this.employers.items.push(result);
        });
    }

    @action
    async duplicateEmployer(duplicateUserInput: DuplicateEmployerInput) {
        const result = await userProfilesService.duplicateEmployer(duplicateUserInput);
        runInAction(() => {
            this.employers.items.push(result);
        });
    }

    @action
    async createEmployerAccount(createUserInput: CreateOrUpdateEmployerAccountInput) {
        const result = await userProfilesService.createEmployerAccount(createUserInput);
        runInAction(() => {
            this.employerAccounts.items.push(result);
        });
    }

    @action
    async updateEmployee(updateUserProfileInput: CreateOrUpdateEmployeeInput) {
        await userProfilesService.updateEmployee(updateUserProfileInput);
    }

    @action
    async updateEmployer(updateUserProfileInput: CreateOrUpdateEmployerInput) {
        await userProfilesService.updateEmployer(updateUserProfileInput);
    }

    @action
    async updateEmployerAccount(updateUserProfileInput: CreateOrUpdateEmployerAccountInput) {
        await userProfilesService.updateEmployerAccount(updateUserProfileInput);
    }

    @action
    async deleteEmployee(entityDto: EntityDto<string>) {
        await userProfilesService.deleteEmployee(entityDto);

        runInAction(() => {
            if (this.employees !== undefined)
                this.employees.items = this.employees.items.filter((x: GetEmployeeOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async deleteEmployer(entityDto: EntityDto<string>) {
        await userProfilesService.deleteEmployer(entityDto);

        runInAction(() => {
            if (this.employers !== undefined)
                this.employers.items = this.employers.items.filter((x: GetEmployerOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async deleteEmployerAccount(entityDto: EntityDto<string>) {
        await userProfilesService.deleteEmployerAccount(entityDto);

        runInAction(() => {
            if (this.employerAccounts !== undefined)
                this.employerAccounts.items = this.employerAccounts.items.filter(
                    (x: GetEmployerAccountOutput) => x.id !== entityDto.id
                );
        });
    }

    @action
    async setTotalBudget(input: UpdateTotalBudgetDto) {
        await userProfilesService.setTotalBudget(input);
    }

    @action
    async getActivationCode(input: EntityDto<string>): Promise<EmployeeActivationTokenDto> {
        return await userProfilesService.getActivationCode(input);
    }

    @action
    async exportActivationCodes() {
        return await userProfilesService.exportActivationCodes();
    }

    @action
    newEmployee() {
        this.editEmployee = {
            userName: '',
            name: '',
            surname: '',
            employeeIdentifier: '',
            emailAddress: '',
            employerId: undefined,
            id: undefined,
            supervisorName: null,
            supervisorSurname: null,
            supervisorEmailAddress: null,
            startingBudget: undefined,
            employerGroups: [],
        };
    }

    @action
    newEmployer() {
        this.editEmployer = {
            employerName: '',
            employeeStartingBudget: 0,
            employeeBudgetLimit: undefined,
            employeeBudgetLimitEnabled: false,
            totalBudget: undefined,
            totalBudgetRemaining: undefined,
            budgetAdjustableEnabled: false,
            id: undefined,
            budgetExceedingOrdersEnabled: false,
            orderRequestRequired: false,
            orderEmailAddress: '',
            sendTaxWageExemptionMail: false,
            surveyResultsExportEnabled: false,
            sendSupplierEndDateEmail: false,
            canManageOwnSupply: false,
            hasApiUser: false,
            budgetFeatures: {
                canViewEmployeeExpenses: false,
                canViewEmployeeBudget: false,
                canEditEmployeeBudget: false,
                canMutateEmployeeBudget: false,
                canBulkEditEmployeeBudget: false,
                canAutomaticIncreaseEmployeeBudget: false,
            },
            employeeFeatures: {
                canImportUsingEmployeeIdentifiers: false,
            },
            branche: Branche.Default,
            enabledModules: ShopModule.HealthAndVitality | ShopModule.LearningAndDevelopment,
            billingEntity: {
                externalBillingId: undefined,
                emailAddress: '',
                includeVAT: true,
                paymentCondition: PaymentCondition.Within14Days,
                invoiceSalutation: '',
                address: {
                    postcode: '',
                    city: '',
                    country: 'Nederland',
                    hasPoBox: false,
                },
                splitInvoicePerEmployer: false,
                splitInvoicePerBudgetCostCenter: false,
            },
            goportaalExternalId: undefined,
        };
    }

    @action
    newEmployerAccount() {
        this.editEmployerAccount = {
            employerId: undefined,
            userName: '',
            name: '',
            surname: '',
            emailAddress: '',
            id: undefined,
        };
    }

    @action
    async getEmployee(entityDto: EntityDto<string>) {
        const result = await userProfilesService.getEmployee(entityDto);
        runInAction(() => {
            this.editEmployee = {
                startingBudget: undefined,
                ...result.user,
                ...result,
                employerId: result.employer.id,
            };
        });
    }

    @action
    async getEmployer(entityDto: EntityDto<string>) {
        const result = await userProfilesService.getEmployer(entityDto);

        runInAction(() => {
            if (result.billingEntity?.address) {
                result.billingEntity.address.hasPoBox = Boolean(result.billingEntity.address.poBoxNumber);
            }

            this.editEmployer = {
                ...result?.user,
                ...result,
                //address: result.address ?? { postcode: '', city: '', country: 'Nederland', hasPoBox: false },
            };

            this.editEmployerBudgetFeatures = result.budgetFeatures;
        });
    }

    @action
    async getEmployerForDuplicate(entityDto: EntityDto<string>) {
        const result = await userProfilesService.getEmployer(entityDto);

        runInAction(() => {
            if (result.billingEntity?.address) {
                result.billingEntity.address.hasPoBox = Boolean(result.billingEntity.address.poBoxNumber);
            }

            this.editEmployer = {
                ...result?.user,
                ...result,
                //address: result.address ?? { postcode: '', city: '', country: 'Nederland', hasPoBox: false },
            };

            this.editEmployerBudgetFeatures = result.budgetFeatures;
            this.editEmployer.employerName = '';
        });
    }

    @action
    async getEmployerAccount(entityDto: EntityDto<string>) {
        const result = await userProfilesService.getEmployerAccount(entityDto);

        runInAction(() => {
            this.editEmployerAccount = {
                ...result?.user,
                ...result,
                employerId: result.employer.id,
            };
        });
    }

    @action
    async getAllEmployers(pagedFilterAndSortedRequest: GetAllEmployersInput) {
        const result = await userProfilesService.getAllEmployers(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.employers = result;
        });
    }

    @action
    async getAllEmployerAccounts(pagedFilterAndSortedRequest: GetAllEmployerAccountsInput) {
        const result = await userProfilesService.getAllEmployerAccounts(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.employerAccounts = result;
        });
    }

    @action
    async getAllEmployees(pagedFilterAndSortedRequest: GetAllEmployeesInput) {
        const result = await userProfilesService.getAllEmployees(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.employees = result;
        });
    }

    @action
    async sendEmployeeActivationMail(input: SendActivationMailInput) {
        await userProfilesService.sendEmployeeActivationMail(input);
    }

    @action
    async sendEmployerActivationMail(entityDto: EntityDto<string>) {
        await userProfilesService.sendEmployerActivationMail(entityDto);
    }

    @action
    async sendEmployerAccountActivationMail(entityDto: EntityDto<string>) {
        await userProfilesService.sendEmployerAccountActivationMail(entityDto);
    }

    @action
    getNewBillingId(): number {
        /*const result = await userProfilesService.getAllEmployers({ maxResultCount: 9999, skipCount: 0, keyword: '' });

        let res =
            Math.max(
                ...result.items.map((value, index, array) => (value.billingId !== undefined ? value.billingId : 0))
            ) + 1;

        return res;*/

        return 1;
        // return (
        //     Math.max(
        //         ...result.items.map((value, index, array) => (value.billingId !== undefined ? value.billingId : 0))
        //     ) + 1
        // );
    }

    @action
    async getStatistics(input: EmployerStatisticsInput) {
        const result = await userProfilesService.getStatistics(input);
        runInAction(() => {
            this.statistics = result;
        });
    }

    @action
    async lockEmployee(entityDto: EntityDto<string>) {
        await userProfilesService.lockEmployee(entityDto);

        runInAction(() => {
            this.employees.items.map((e) =>
                e.id === entityDto.id ? { ...e, user: { ...e.user, isActive: false } } : e
            );
        });
    }
}

export default UserProfileStore;
