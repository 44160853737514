import { EntityDto } from 'src/services/dto/entityDto';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import http from '../httpService';
import { PagedUserResultRequestDto } from './dto/PagedUserResultRequestDto';
import { PasswordResetInput } from './dto/PasswordResetInput';
import { RequestPasswordResetInput } from './dto/RequestPasswordResetInput';
import { ChangeLanguageInput } from './dto/changeLanguageInput';
import { CreateOrUpdateUserInput } from './dto/createOrUpdateUserInput';
import { GetAllUserOutput } from './dto/getAllUserOutput';
import { UpdateUserInput } from './dto/updateUserInput';

class UserService {
    public async create(createUserInput: CreateOrUpdateUserInput) {
        const result = await http.post('api/services/app/User/Create', createUserInput);
        return result.data.result;
    }

    public async update(updateUserInput: UpdateUserInput) {
        const result = await http.put('api/services/app/User/Update', updateUserInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto) {
        const result = await http.delete('api/services/app/User/Delete', { params: entityDto });
        return result.data;
    }

    public async getRoles() {
        const result = await http.get('api/services/app/User/GetRoles');
        return result.data.result.items;
    }

    public async changeLanguage(changeLanguageInput: ChangeLanguageInput) {
        const result = await http.post('api/services/app/User/ChangeLanguage', changeLanguageInput);
        return result.data;
    }

    public async get(entityDto: EntityDto): Promise<CreateOrUpdateUserInput> {
        const result = await http.get('api/services/app/User/Get', { params: entityDto });
        return result.data.result;
    }

    public async getAll(
        pagedFilterAndSortedRequest: PagedUserResultRequestDto
    ): Promise<PagedResultDto<GetAllUserOutput>> {
        const result = await http.get('api/services/app/User/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }

    public async requestPasswordReset(requestPasswordResetInput: RequestPasswordResetInput) {
        await http.post('api/services/app/User/RequestPasswordReset', requestPasswordResetInput);
    }

    public async passwordReset(passwordResetInput: PasswordResetInput) {
        await http.post('api/services/app/User/PasswordReset', passwordResetInput);
    }

    public async createApiKey(entityDto: EntityDto): Promise<string> {
        const result = await http.post(`api/services/app/ApiKey/Create/${entityDto.id}`);
        return result.data.result;
    }
}

export default new UserService();
