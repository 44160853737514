import { RuleObject } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import { routers } from 'src/components/Router/router.config';
import { L } from 'src/lib/abpUtility';
import { InterventionRequestStatus } from 'src/models/Interventions/InterventionRequestStatus';
import { BlockType } from '../models/Article/BlockType';
import { GetArticleContentBlockOutput } from '../services/article/dto/getArticleContentBlockOutput';

type Validator<T> = (rule: RuleObject, value: T) => Promise<void | any> | void;

export const EmailAddressRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Utils {
    loadScript(url: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
    }

    loadScriptHead(url: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.head.appendChild(script);
    }

    extend(...args: any[]) {
        let options;
        let name;
        let src;
        let srcType;
        let copy;
        let copyIsArray;
        let clone;
        let target = args[0] || {};
        let i = 1;
        const length = args.length;
        let deep = false;
        if (typeof target === 'boolean') {
            deep = target;
            target = args[i] || {};
            i++;
        }
        if (typeof target !== 'object' && typeof target !== 'function') {
            target = {};
        }
        if (i === length) {
            target = this;
            i--;
        }
        for (; i < length; i++) {
            // tslint:disable-next-line: no-conditional-assignment
            if ((options = args[i]) !== null) {
                // tslint:disable-next-line: forin
                for (name in options) {
                    src = target[name];
                    copy = options[name];
                    if (target === copy) {
                        continue;
                    }
                    srcType = Array.isArray(src) ? 'array' : typeof src;
                    // tslint:disable-next-line: no-conditional-assignment
                    if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
                        if (copyIsArray) {
                            copyIsArray = false;
                            clone = src && srcType === 'array' ? src : [];
                        } else {
                            clone = src && srcType === 'object' ? src : {};
                        }
                        target[name] = this.extend(deep, clone, copy);
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
        }

        return target;
    }

    getPageTitle = (pathname: string) => {
        // tslint:disable-next-line: no-shadowed-constiable
        const route = routers.filter((route) => route.path === pathname);
        const localizedAppName = L('AppName');
        if (!route || route.length === 0) {
            return localizedAppName;
        }

        return L(route[0].title) + ' | ' + localizedAppName;
    };

    getRoute = (path: string): any => {
        return routers.filter((route) => route.path === path)[0];
    };

    setLocalization() {
        if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
            const language = 'nl-NL';
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                language,
                new Date(new Date().getTime() + 5 * 365 * 86400000),
                abp.appPath
            );
        }
    }

    getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }

        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }

        return abp.timing.localClockProvider;
    }

    getPasswordValidationRegex() {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&,./<>?±§:;|\\'"{}[\]\-_`~]).{6,32}$/;
    }

    lastElement<T>(items: T[]) {
        return items[items.length - 1];
    }

    validateEmailAddress(rule: any, value: string) {
        if (!rule.required && (!value || value.length === 0)) {
            return Promise.resolve();
        }

        if (!value) {
            return Promise.reject(L('PleaseEnterEmail'));
        }

        if (!EmailAddressRegex.test(value)) {
            return Promise.reject(L('EmailAddressIncorrect'));
        }

        return Promise.resolve();
    }

    validateNotRequiredEmailAddress(rule: any, value: string) {
        if (!value) {
            return Promise.resolve();
        }

        // eslint-disable-next-line
        if (!EmailAddressRegex.test(value)) {
            return Promise.reject(L('EmailAddressIncorrect'));
        }

        return Promise.resolve();
    }

    validateUrl(rule: any, value: string) {
        if (!rule.required && (!value || value.length === 0)) {
            return Promise.resolve();
        }

        if (!value) {
            return Promise.reject(L('PleaseEnterUrlWithProtocol'));
        }

        const urlRegex = new RegExp('^http[s]?://[a-zA-Z0-9-.]+.[a-zA-Z]{2,6}(\\S*)$');
        if (!urlRegex.test(value)) {
            return Promise.reject(L('PleaseEnterUrlWithProtocol'));
        }

        return Promise.resolve();
    }

    validateEmailAddressOrUrl(rule: any, value: string) {
        if (!rule.required && (!value || value.length === 0)) {
            return Promise.resolve();
        }

        if (!value) {
            return Promise.reject(L('PleaseEnterExternalOrderEmailAddressOrUrl'));
        }

        const isValidEmailAddress = EmailAddressRegex.test(value);

        if (!isValidEmailAddress && !Utils.isValidUrl(value)) {
            return Promise.reject(L('PleaseEnterExternalOrderEmailAddressOrUrl'));
        }

        return Promise.resolve();
    }

    validateBlock(rule: any, value: GetArticleContentBlockOutput) {
        if (!value) {
            return Promise.reject(L('PleaseEnterText'));
        }

        if (value.type === BlockType.CollapsibleText && (!value.title || !value.title.trim())) {
            return Promise.reject(L('PleaseEnterText'));
        }

        return Promise.resolve();
    }

    validateNoWhitespace(rule: any, value: string, messageIdentifier: string, required: boolean) {
        if (required && !value) {
            return Promise.reject(L('ThisFieldIsRequired'));
        }

        if (/\s/.test(value)) {
            return Promise.reject(L(messageIdentifier));
        }

        return Promise.resolve();
    }

    validateChangeRequestJudgementStatus: Validator<number> = (rule, value) => {
        if (!rule.required && value == null) {
            return Promise.resolve();
        }

        if (
            value !== InterventionRequestStatus.ChangeApproved &&
            value !== InterventionRequestStatus.DeletionApproved &&
            value !== InterventionRequestStatus.Rejected
        ) {
            return Promise.reject(L('SelectStatus'));
        }

        return Promise.resolve();
    };

    delay = (interval: number) => new Promise((resolve) => setTimeout(resolve, interval));

    static isValidUrl(input: string) {
        try {
            const url = new URL(input);

            return (
                (url.protocol === 'http:' || url.protocol === 'https:') &&
                url.host.lastIndexOf('.') !== -1 &&
                !url.origin.endsWith('.')
            );
        } catch (err) {
            return false;
        }
    }

    isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    moveElement(array: any[], oldIndex: number, newIndex: number) {
        if (newIndex >= array.length) {
            var k = newIndex - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    }

    parseFloatFromString = (value: string): number | undefined => {
        value = String(value).trim();

        if ('' === value) {
            return undefined;
        }

        // Remove all non-digit characters
        var split = value.split(/[^\dE-]+/);

        if (1 === split.length) {
            // There's no decimal part
            return parseFloat(value);
        }

        for (var i = 0; i < split.length; i++) {
            if ('' === split[i]) {
                return undefined;
            }
        }

        // Use the last part as decimal
        var decimal = split.pop();

        if (decimal?.length === 3) {
            // Not a decimal, but thousands separator
            // Push back onto list
            split.push(decimal);
            decimal = '00';
        }

        // Reconstruct the number using dot as decimal separator
        return parseFloat(split.join('') + '.' + decimal);
    };

    parseMomentFromString = (value: string): Moment | undefined => {
        let result: Moment | undefined = undefined;

        let dateFormat = value.includes('/') ? 'DD/MM/YYYY' : 'DD-MM-YYYY';
        const numberOfColons = value.match(/\:/g)?.length ?? 0;

        if (numberOfColons > 0) {
            dateFormat += ' ' + (numberOfColons == 1 ? 'HH:mm' : 'HH:mm:ss');
        }

        result = moment(value, dateFormat);

        if (result && result.isValid()) {
            return result.utc();
        }

        return undefined;
    };
}

export default new Utils();
