import { action, makeObservable, observable, runInAction } from 'mobx';

import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { GetAllUsersInput } from 'src/services/user/dto/GetAllUsersInput';
import type { PasswordResetInput } from 'src/services/user/dto/PasswordResetInput';
import type { RequestPasswordResetInput } from 'src/services/user/dto/RequestPasswordResetInput';
import type { CreateOrUpdateUserInput } from 'src/services/user/dto/createOrUpdateUserInput';
import { GetRoles } from 'src/services/user/dto/getRolesOuput';
import { GetUserOutput } from 'src/services/user/dto/getUserOutput';
import type { UpdateUserInput } from 'src/services/user/dto/updateUserInput';
import userService from 'src/services/user/userService';

class UserStore {
    @observable users: PagedResultDto<GetUserOutput>;
    @observable editUser: CreateOrUpdateUserInput;
    @observable roles: GetRoles[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createUserInput: CreateOrUpdateUserInput) {
        const result = await userService.create(createUserInput);
        runInAction(() => {
            this.users.items.push(result);
        });
    }

    @action
    async update(updateUserInput: UpdateUserInput) {
        const result = await userService.update(updateUserInput);
        runInAction(() => {
            this.users.items = this.users.items.map((x: GetUserOutput) => {
                if (x.id === updateUserInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto) {
        await userService.delete(entityDto);
        runInAction(() => {
            this.users.items = this.users.items.filter((x: GetUserOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async getRoles() {
        const result = await userService.getRoles();
        runInAction(() => {
            this.roles = result;
        });
    }

    @action
    async get(entityDto: EntityDto) {
        const result = await userService.get(entityDto);
        runInAction(() => {
            this.editUser = result;
        });
    }

    @action
    createUser() {
        this.editUser = {
            userName: '',
            name: '',
            surname: '',
            emailAddress: '',
            twoFactorEmailAddress: '',
            isActive: false,
            roleNames: [],
            password: '',
            id: 0,
        };
        this.roles = [];
    }

    @action
    async getAll(pagedFilterAndSortedRequest: GetAllUsersInput) {
        const result = await userService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.users = result;
        });
    }

    @action
    async requestPasswordReset(requestPasswordInput: RequestPasswordResetInput) {
        await userService.requestPasswordReset(requestPasswordInput);
    }

    @action
    async passwordReset(passwordResetInput: PasswordResetInput) {
        await userService.passwordReset(passwordResetInput);
    }

    async changeLanguage(languageName: string) {
        await userService.changeLanguage({ languageName });
    }
}

export default UserStore;
