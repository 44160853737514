import AccountStore from './accountStore';
import AuthenticationStore from './authenticationStore';
import BudgetStore from './budgetStore';
import CategoryStore from './categoryStore';
import EmployerGroupStore from './employerGroupStore';
import ExternalInterventionStore from './externalInterventionStore';
import InterventionStore from './interventionStore';
import InterventionThemeStore from './interventionThemeStore';
import OrderStore from './orderStore';
import FeedbackStore from './feedbackStore';
import PageStore from './pageStore';
import QuestionStore from './questionStore';
import RoleStore from './roleStore';
import { sessionInstance } from './sessionStore';
import SuggestionStore from './suggestionStore';
import SupplierStore from './supplierStore';
import SurveyStore from './surveyStore';
import SurveySubmissionStore from './surveySubmissionStore';
import TenantStore from './tenantStore';
import ThemeStore from './themeStore';
import UserProfileStore from './userProfileStore';
import UserStore from './userStore';
import DashboardStore from './dashboardStore';
import PushNotificationStore from './pushNotificationStore';
import ArticleStore from './articleStore';
import FeaturedInterventionStore from './featuredInterventionStore';
import CarouselStore from './carouselStore';
import { InterventionChangeRequestStore } from './interventionChangeRequestStore';
import TaxWageSurveyStore from './taxWageSurveyStore';
import EmployerFilterStore from './employerFilterStore';
import ApiUserStore from './apiUserStore';
import BillingEntityStore from './billingEntityStore';
import FaqStore from './faqStore';

export default function initializeStores() {
    return {
        accountStore: new AccountStore(),
        apiUserStore: new ApiUserStore(),
        articleStore: new ArticleStore(),
        authenticationStore: new AuthenticationStore(),
        budgetStore: new BudgetStore(),
        billingEntityStore: new BillingEntityStore(),
        carouselStore: new CarouselStore(),
        categoryStore: new CategoryStore(),
        dashboardStore: new DashboardStore(),
        featuredInterventionStore: new FeaturedInterventionStore(),
        employerFilterStore: new EmployerFilterStore(),
        employerGroupStore: new EmployerGroupStore(),
        interventionChangeRequestStore: new InterventionChangeRequestStore(sessionInstance),
        externalInterventionStore: new ExternalInterventionStore(),
        interventionStore: new InterventionStore(),
        interventionThemeStore: new InterventionThemeStore(),
        orderStore: new OrderStore(),
        feedbackStore: new FeedbackStore(),
        pageStore: new PageStore(),
        pushNotificationStore: new PushNotificationStore(),
        questionStore: new QuestionStore(),
        roleStore: new RoleStore(),
        sessionStore: sessionInstance,
        suggestionStore: new SuggestionStore(),
        supplierStore: new SupplierStore(),
        surveyStore: new SurveyStore(),
        surveySubmissionStore: new SurveySubmissionStore(),
        taxWageSurveyStore: new TaxWageSurveyStore(),
        tenantStore: new TenantStore(),
        themeStore: new ThemeStore(),
        userProfileStore: new UserProfileStore(),
        userStore: new UserStore(),
        faqStore: new FaqStore(),
    };
}
