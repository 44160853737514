import tokenAuthService from './tokenAuth/tokenAuthService';

class TokenService {
    refreshTokenStorageKey = 'LekkerBezig.RefreshToken';

    cachedRefreshPromise: Promise<string> | null = null;

    public async getToken(): Promise<string> {
        const currentAccessToken = abp.auth.getToken();

        if (currentAccessToken != null) {
            // Token still valid, return
            return Promise.resolve(currentAccessToken);
        }

        return this.startRefreshAccessToken();
    }

    public setToken(accessToken: string, refreshToken: string, expirationInSeconds?: number) {
        const tokenExpireDate = expirationInSeconds
            ? new Date(new Date().getTime() + 1000 * expirationInSeconds)
            : undefined;

        abp.auth.clearToken();
        abp.auth.setToken(accessToken, tokenExpireDate);

        // Store refresh token in storage
        this.setRefreshToken(refreshToken);
    }

    public startRefreshAccessToken(): Promise<string> {
        if (this.cachedRefreshPromise == null) {
            // No active refresh call, start a new one
            if (this.hasRefreshToken()) {
                this.cachedRefreshPromise = this.refreshAccessToken();
            } else {
                return Promise.resolve('');
            }
        }

        // Return active refresh promise
        return this.cachedRefreshPromise!;
    }

    refreshAccessToken(): Promise<string> {
        const refreshToken = this.getRefreshToken();

        if (refreshToken == null) {
            return Promise.reject();
        }

        return tokenAuthService
            .refreshToken({
                refreshToken: refreshToken,
                deviceIdentifier: '',
                target: 'admin',
            })
            .then((response: any) => {
                this.setToken(response.accessToken, response.refreshToken, response.expireInSeconds);
                return response.accessToken;
            })
            .finally(() => (this.cachedRefreshPromise = null));
    }

    public setRefreshToken(token: string) {
        localStorage.setItem(this.refreshTokenStorageKey, token);
    }

    public getRefreshToken(): string | null {
        return localStorage.getItem(this.refreshTokenStorageKey);
    }

    public hasRefreshToken(): boolean {
        return this.getRefreshToken() != null;
    }

    public clear() {
        this.cachedRefreshPromise = null;
        abp.auth.clearToken();
        localStorage.removeItem(this.refreshTokenStorageKey);
    }
}

export default new TokenService();
