import type { CancelToken } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import dashboardService from 'src/services/dashboard/dashboardService';
import InterventionStatisticsOutput from 'src/services/dashboard/dto/InterventionStatisticsOutput';
import ActivationRankingStatisticsOutput from 'src/services/dashboard/dto/activationRankingStatisticsOutput';
import ActivationStatisticsOutput from 'src/services/dashboard/dto/activationStatisticsOutput';
import FeedbackItemOutput from 'src/services/dashboard/dto/feedbackItemOutput';
import type FeedbackStatisticsOutput from 'src/services/dashboard/dto/feedbackStatisticsOutput';
import type GeneralStatisticsOutput from 'src/services/dashboard/dto/generalStatisticsOutput';
import type NotificationStatisticsOutput from 'src/services/dashboard/dto/notificationStatisticsOutput';
import OrderRankingStatisticsOutput from 'src/services/dashboard/dto/orderRankingStatisticsOutput';
import type PopularInterventionStatisticsInput from 'src/services/dashboard/dto/popularInterventionStatisticsInput';
import RevenueRankingStatisticsOutput from 'src/services/dashboard/dto/revenueRankingStatisticsOutput';
import RevenueStatisticsOutput from 'src/services/dashboard/dto/revenueStatisticsOutput';
import type StatisticsInput from 'src/services/dashboard/dto/statisticsInput';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { InterventionStatistic } from 'src/services/intervention/dto/interventionStatistic';
import type BudgetStatisticsOutput from '../services/dashboard/dto/budgetStatisticsOutput';

class DashboardStore {
    @observable generalStatistics: GeneralStatisticsOutput;
    @observable activationStatistics: ActivationStatisticsOutput[];
    @observable activationRankingStatistics: ActivationRankingStatisticsOutput[];
    @observable interventionStatistics: InterventionStatisticsOutput[];
    @observable orderRankingStatistics: OrderRankingStatisticsOutput[];
    @observable popularInterventionStatistics: PagedResultDto<InterventionStatistic>;
    @observable revenueStatistics: RevenueStatisticsOutput[];
    @observable revenueRankingStatistics: RevenueRankingStatisticsOutput[];
    @observable budgetStatistics: BudgetStatisticsOutput;
    @observable notificationStatistics: NotificationStatisticsOutput;
    @observable feedbackStatistics: FeedbackStatisticsOutput;
    @observable feedback: PagedResultDto<FeedbackItemOutput>;

    constructor() {
        makeObservable(this);
    }

    @action
    async getGeneralStatistics(input: StatisticsInput, cancelToken: CancelToken) {
        const res = await dashboardService.getGeneralStatistics(input, cancelToken);
        runInAction(() => {
            this.generalStatistics = res;
        });
    }

    @action
    async getActivationStatistics(input: StatisticsInput) {
        const res = await dashboardService.getActivationStatistics(input);
        runInAction(() => {
            this.activationStatistics = res;
        });
    }

    @action
    async getActivationRankingStatistics(input: StatisticsInput) {
        const res = await dashboardService.getActivationRankingStatistics(input);
        runInAction(() => {
            this.activationRankingStatistics = res;
        });
    }

    @action
    async getInterventionStatistics(input: StatisticsInput) {
        const res = await dashboardService.getInterventionStatistics(input);
        runInAction(() => {
            this.interventionStatistics = res;
        });
    }

    @action
    async getPopularInterventionStatistics(input: PopularInterventionStatisticsInput) {
        const res = await dashboardService.getPopularInterventionStatistics(input);
        runInAction(() => {
            this.popularInterventionStatistics = res;
        });
    }

    @action
    async getRevenueStatistics(input: StatisticsInput) {
        const res = await dashboardService.getRevenueStatistics(input);
        runInAction(() => {
            this.revenueStatistics = res;
        });
    }

    @action
    async getRevenueRankingStatistics(input: StatisticsInput) {
        const res = await dashboardService.getRevenueRankingStatistics(input);
        runInAction(() => {
            this.revenueRankingStatistics = res;
        });
    }

    @action
    async getBudgetStatistics(input: StatisticsInput) {
        const res = await dashboardService.getBudgetStatistics(input);
        runInAction(() => {
            this.budgetStatistics = res;
        });
    }

    @action
    async getOrderRankingStatistics(input: StatisticsInput) {
        const res = await dashboardService.getOrderRankingStatistics(input);
        runInAction(() => {
            this.orderRankingStatistics = res;
        });
    }

    @action
    async getNotificationStatistics(input: StatisticsInput) {
        const res = await dashboardService.getNotificationStatistics(input);
        runInAction(() => {
            this.notificationStatistics = res;
        });
    }

    @action
    async getFeedbackStatistics(input: StatisticsInput) {
        const res = await dashboardService.getFeedbackStatistics(input);
        runInAction(() => {
            this.feedbackStatistics = res;
        });
    }

    @action
    async getAllFeedback(input: StatisticsInput, request: PagedFilterAndSortedRequest) {
        const res = await dashboardService.getAllFeedback(input, request);
        runInAction(() => {
            this.feedback = res;
        });
    }
}

export default DashboardStore;
