import { action, makeObservable, observable, runInAction } from 'mobx';
import ArticleService from 'src/services/article/articleService';
import type { GetArticleOutput } from 'src/services/article/dto/getArticleOutput';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { ArticleType } from '../models/Article/ArticleType';

class ArticleStore {
    @observable articles: PagedResultDto<GetArticleOutput>;
    @observable editArticle: GetArticleOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createArticleInput: GetArticleOutput) {
        const result = await ArticleService.create(createArticleInput);
        runInAction(() => {
            this.articles.items.push(result);
        });
    }

    @action
    async update(updateArticleInput: GetArticleOutput) {
        const result = await ArticleService.update(updateArticleInput);
        runInAction(() => {
            this.articles.items = this.articles.items.map((x: GetArticleOutput) => {
                if (x.id === updateArticleInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await ArticleService.delete(entityDto);
        runInAction(() => {
            this.articles.items = this.articles.items.filter((x: GetArticleOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await ArticleService.get(entityDto);
        runInAction(() => {
            this.editArticle = result;
        });
    }

    @action
    createArticle() {
        this.editArticle = {
            id: '',
            isPublished: false,
            articleTypes: ArticleType.Content,
            order: 0,
            isAvailableForLoggedInUsers: true,
            isAvailableForAnonymousUsers: false,
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    headerImageUrl: '',
                    title: '',
                    introduction: '',
                    body: [],
                    readMoreUrl: '',
                    relatedInterventions: [],
                },
            ],
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await ArticleService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.articles = result;
        });
    }
}

export default ArticleStore;
