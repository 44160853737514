import { Modal } from 'antd';
import axios from 'axios';
import { L } from 'src/lib/abpUtility';
import AppConsts from './../lib/appconst';
import tokenService from './tokenService';
// tslint:disable-next-line: no-var-requires
const qs = require('qs');

const http = axios.create({
    baseURL: AppConsts.remoteServiceBaseUrl,
    timeout: 100000, // was: 30000,
    paramsSerializer(params) {
        return qs.stringify(params, {
            encode: false,
        });
    },
});

export const cancelTokenSource = axios.CancelToken.source();

http.interceptors.request.use(
    async (config) => {
        if (!anonymousUrls.some((u) => config.url?.endsWith(u))) {
            const token = await tokenService.getToken();

            if (!!token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
        }

        let currentLanguage = abp.localization.currentLanguage.name;

        if (currentLanguage === undefined) {
            currentLanguage = abp.utils.getCookieValue('Abp.Localization.CultureName');
        }

        config.headers['Accept-Language'] = currentLanguage;
        config.headers['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Request cancelled
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        // Not an Axios error
        if (!axios.isAxiosError(error)) {
            console.warn('[Axios.interceptors.response] Unexpected error:', error);
            return Promise.reject(error);
        }

        // No response received (e.g. network error, CORS error)
        if (!error.response) {
            Modal.error({ content: L('UnknownError') });
            return Promise.reject(error);
        }

        // Unauthorized error
        if (
            error.response.status === 401 &&
            !window.location.pathname.endsWith('/user/login') &&
            !anonymousUrls.some((u) => error.response?.config.url?.endsWith(u))
        ) {
            const originalRequestConfig = error.config;
            if (tokenService.hasRefreshToken() && originalRequestConfig) {
                // Retry with refreshed access token
                try {
                    await tokenService.startRefreshAccessToken();
                    delete originalRequestConfig.headers['Authorization'];
                    return http.request(originalRequestConfig);
                } catch (e) {
                    handleError(e);
                }
            } else {
                return handleError(error);
            }
        }

        // Refresh token error
        if (error.response.config.url?.endsWith('api/TokenAuth/AuthenticateByRefreshToken')) {
            return handleError(error);
        }

        // Skip the statistics errors
        if ((error.response.config.url?.indexOf('GetActivationRankingStatistics') ?? -1) > 0) {
            return Promise.reject(error);
        }

        // skip errors in getting progress updates
        if (error.response.config.url?.includes('GetImportProgress')) {
            return Promise.reject(error);
        }

        // Generic error -> don't show message
        if (error.config?.disableDefaultErrorHandling) {
            return Promise.reject(error);
        }

        // Generic error -> show message
        const errorData = error.response.data?.error;
        if (errorData) {
            if (!!errorData.message && errorData.details) {
                Modal.error({
                    title: errorData.message,
                    content: errorData.details,
                });
            } else if (!!errorData.identifier && !!errorData.title) {
                // LekkerBezigException with title
                Modal.error({
                    title: errorData.title,
                    content: errorData.message,
                });
            } else if (!!errorData.identifier && !!errorData.message) {
                // LekkerBezigException with only message
                Modal.error({
                    title: L('Error'),
                    content: errorData.message,
                });
            } else if (!!errorData.message) {
                Modal.error({
                    title: L('Error') + ': ' + error.response.status,
                    content: errorData.message,
                });
            }
            return Promise.reject(error);
        }

        // Unhandled error
        console.warn('[Axios.interceptors.response] Unhandled error:', error);
        return Promise.reject(error);
    }
);

/**
 * Clear session storage and token service and reload the page
 */
const handleError = <T>(error: T): Promise<T> => {
    sessionStorage.clear();
    tokenService.clear();
    window.location.reload();
    return Promise.reject(error);
};

const anonymousUrls = [
    'api/TokenAuth/Authenticate',
    'api/TokenAuth/AuthenticateTwoFactor',
    'api/TokenAuth/AuthenticateByRefreshToken',
    'api/services/app/User/PasswordReset',
    'api/services/app/User/RequestPasswordReset',
];

export default http;
