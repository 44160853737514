import { action, makeObservable, observable, runInAction } from 'mobx';
import RoleEditModel from 'src/models/Roles/roleEditModel';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { PagedRoleResultRequestDto } from 'src/services/role/dto/PagedRoleResultRequestDto';
import type { CreateRoleInput } from 'src/services/role/dto/createRoleInput';
import { GetAllPermissionsOutput } from 'src/services/role/dto/getAllPermissionsOutput';
import { GetAllRoleOutput } from 'src/services/role/dto/getAllRoleOutput';
import type { GetRoleAsyncInput } from 'src/services/role/dto/getRolesAsyncInput';
import type { UpdateRoleInput } from 'src/services/role/dto/updateRoleInput';
import roleService from 'src/services/role/roleService';

class RoleStore {
    @observable roles: PagedResultDto<GetAllRoleOutput>;
    @observable roleEdit: RoleEditModel = new RoleEditModel();
    @observable allPermissions: GetAllPermissionsOutput[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createRoleInput: CreateRoleInput) {
        await roleService.create(createRoleInput);
    }

    @action
    createRole() {
        this.roleEdit = {
            grantedPermissionNames: [],
            role: {
                name: '',
                displayName: '',
                description: '',
                isStatic: false,
                id: 0,
            },
            permissions: [{ name: '', displayName: '', description: '' }],
        };
    }

    @action
    async getRolesAsync(getRoleAsyncInput: GetRoleAsyncInput) {
        await roleService.getRolesAsync(getRoleAsyncInput);
    }

    @action
    async update(updateRoleInput: UpdateRoleInput) {
        const result = await roleService.update(updateRoleInput);
        runInAction(() => {
            this.roles.items = this.roles.items.map((x: GetAllRoleOutput) => {
                return x.id === updateRoleInput.id ? result : x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto) {
        await roleService.delete(entityDto);
        runInAction(() => {
            this.roles.items = this.roles.items.filter((x: GetAllRoleOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async getAllPermissions() {
        const result = await roleService.getAllPermissions();
        runInAction(() => {
            this.allPermissions = result;
        });
    }

    @action
    async getRoleForEdit(entityDto: EntityDto) {
        const result = await roleService.getRoleForEdit(entityDto);
        runInAction(() => {
            this.roleEdit.grantedPermissionNames = result.grantedPermissionNames;
            this.roleEdit.permissions = result.permissions;
            this.roleEdit.role = result.role;
        });
    }

    @action
    async get(entityDto: EntityDto) {
        const result = await roleService.get(entityDto);
        runInAction(() => {
            this.roles = result.data.result;
        });
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedRoleResultRequestDto) {
        const result = await roleService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.roles = result;
        });
    }
}

export default RoleStore;
