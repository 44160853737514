import { action, makeObservable, observable, runInAction } from 'mobx';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { GetInterventionThemeOutput } from 'src/services/interventionTheme/dto/getInterventionThemeOutput';
import InterventionThemeService from 'src/services/interventionTheme/interventionThemeService';

class InterventionThemeStore {
    @observable interventionThemes: PagedResultDto<GetInterventionThemeOutput>;

    constructor() {
        makeObservable(this);
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await InterventionThemeService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.interventionThemes = result;
        });
    }
}

export default InterventionThemeStore;
