import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { CreateOrUpdateEmployerGroupInput } from './dto/createOrUpdateEmployerGroupInput';
import { EmployerGroupOutput } from './dto/employerGroupOutput';
import { GetAllEmployerGroupsInput } from './dto/getAllEmployerGroupsInput';

class EmployerGroupService {
  public async create(createThemeInput: CreateOrUpdateEmployerGroupInput) {
    const result = await http.post('api/services/app/EmployerGroup/Create', createThemeInput);
    return result.data.result;
  }

  public async update(updateThemeInput: CreateOrUpdateEmployerGroupInput) {
    const result = await http.put('api/services/app/EmployerGroup/Update', updateThemeInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto<string>) {
    const result = await http.delete('api/services/app/EmployerGroup/Delete', { params: entityDto });
    return result.data;
  }

  public async get(entityDto: EntityDto<string>): Promise<EmployerGroupOutput> {
    const result = await http.get('api/services/app/EmployerGroup/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(employerGroupRequest: GetAllEmployerGroupsInput): Promise<PagedResultDto<EmployerGroupOutput>> {
    const result = await http.get('api/services/app/EmployerGroup/GetAll', { params: employerGroupRequest });
    return result.data.result;
  }
}

export default new EmployerGroupService();
