import { UpdateCategoryInput } from './dto/updateCategoryInput';
import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { GetAllCategoryOutput } from './dto/getAllCategoryOutput';
import { GetCategoryOutput } from './dto/getCategoryOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { CreateOrUpdateCategoryInput } from './dto/createOrUpdateCategoryInput';
import { UpdateCategoryCrossCorrelationsInput } from './dto/updateCategoryCrossCorrelationsInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';

class CategoryService {
  public async create(createCategoryInput: CreateOrUpdateCategoryInput) {
    const result = await http.post('api/services/app/Category/Create', createCategoryInput);
    return result.data.result;
  }

  public async update(updateCategoryInput: UpdateCategoryInput) {
    const result = await http.put('api/services/app/Category/Update', updateCategoryInput);
    return result.data;
  }

  public async updateCrossCorrelations(updateCrossCorrelations: UpdateCategoryCrossCorrelationsInput) {
    const result = await http.put('api/services/app/Category/UpdateCrossCorrelations', updateCrossCorrelations);
    return result.data;
  }

  public async delete(entityDto: EntityDto) {
    const result = await http.delete('api/services/app/Category/Delete', { params: entityDto });
    return result.data;
  }

  public async get(entityDto: EntityDto): Promise<GetCategoryOutput> {
    const result = await http.get('api/services/app/Category/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<GetAllCategoryOutput>> {
    const result = await http.get('api/services/app/Category/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }
}

export default new CategoryService();
