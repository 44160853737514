export enum BudgetType {
    // Personal (regular) budget
    Employee = 1,

    // Employer pays for intervention (PMO)
    Employer = 2,

    // STAP-budget
    Stap = 4,
}
