import { action, makeObservable, observable, runInAction } from 'mobx';

import { DiscountType } from 'src/models/Interventions/DiscountType';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { UpdateInterventionQuestionMatchInput } from 'src/services/intervention/dto/UpdateInterventionQuestionMatch';
import { CreateOrUpdateInterventionCategorizationInput } from 'src/services/intervention/dto/createOrUpdateInterventionCategorizationInput';
import type { CreateOrUpdateInterventionInput } from 'src/services/intervention/dto/createOrUpdateInterventionInput';
import type { GetAllInterventionInput } from 'src/services/intervention/dto/getAllInterventionInput';
import { GetAllInterventionOutput } from 'src/services/intervention/dto/getAllInterventionOutput';
import { GetAllPublicInterventionOutput } from 'src/services/intervention/dto/getAllPublicInterventionOutput';
import { GetInterventionOutput } from 'src/services/intervention/dto/getInterventionOutput';
import { GetInterventionQuestionMatch } from 'src/services/intervention/dto/getInterventionQuestionMatch';
import { InterventionStatistic } from 'src/services/intervention/dto/interventionStatistic';
import type InterventionStatisticsInput from 'src/services/intervention/dto/interventionStatisticsInput';
import { OrderDevelopment } from 'src/services/intervention/dto/orderDevelopment';
import type { UpdateInterventionCategorizationInput } from 'src/services/intervention/dto/updateInterventionCategorizationInput';
import InterventionService from 'src/services/intervention/interventionService';
import { BudgetType } from '../models/Interventions/BudgetType';

class InterventionStore {
    @observable interventions: PagedResultDto<GetAllInterventionOutput>;
    @observable publicInterventions: PagedResultDto<GetAllPublicInterventionOutput>;
    @observable editIntervention: CreateOrUpdateInterventionInput;
    @observable categorizations: CreateOrUpdateInterventionCategorizationInput[];
    @observable editInterventionQuestionMatches: GetInterventionQuestionMatch[];
    @observable interventionStatisticsAvailable: boolean;
    @observable interventionStatistics: PagedResultDto<InterventionStatistic>;
    @observable orderDevelopment: OrderDevelopment[];

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createInterventionInput: CreateOrUpdateInterventionInput) {
        const result = await InterventionService.create(createInterventionInput);
        runInAction(() => {
            this.interventions.items.push(result);
        });
    }

    @action
    async update(updateInterventionInput: CreateOrUpdateInterventionInput) {
        const result = await InterventionService.update(updateInterventionInput);
        runInAction(() => {
            this.interventions.items = this.interventions.items.map((x: GetInterventionOutput) => {
                if (x.id === updateInterventionInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await InterventionService.delete(entityDto);
        runInAction(() => {
            this.interventions.items = this.interventions.items.filter(
                (x: GetInterventionOutput) => x.id !== entityDto.id
            );
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await InterventionService.get(entityDto);
        runInAction(() => {
            this.editIntervention = result;
            this.categorizations = result.categorizations;
            this.editInterventionQuestionMatches = result.questionMatches;
        });
    }

    @action
    async updateCategorizations(updateInterventionCategorizations: UpdateInterventionCategorizationInput) {
        return InterventionService.updateCategorizations(updateInterventionCategorizations);
    }

    @action
    async updateInterventionQuestionMatch(updateInterventionQuestionMatchInput: UpdateInterventionQuestionMatchInput) {
        return InterventionService.updateIntervetionQuestionMatch(updateInterventionQuestionMatchInput);
    }

    @action
    createIntervention() {
        this.editIntervention = {
            supplierId: '',
            showUrlInApp: false,
            vatPercentage: 0,
            kickbackFeePercentage: 0,
            stapKickbackFeePercentage: 0,
            kickbackFeeVATPercentage: 21,
            minNumberOfParticipants: 0,
            maxNumberOfParticipants: 0,
            startDateDetermination: undefined,
            id: undefined,
            type: undefined,
            canBeSuggestion: false,
            externalSource: undefined,
            externalId: undefined,
            excludeFromSubscription: false,
            costLines: [
                {
                    id: 0,
                    lineNumber: 1,
                    costInclVat: 0,
                    costExclVat: 0,
                    vatPercentage: 21,
                    taxableWageOverride: false,
                    excludedFromKickbackFee: false,
                    budgetTypes: BudgetType.Employee,
                    discountTypes: DiscountType.None,
                    localizations: [
                        {
                            id: undefined,
                            localeId: 'nl',
                            locale: {
                                id: 'nl',
                                description: 'Nederlands',
                            },
                            parentId: 0,
                            description: '',
                            summaryText: '',
                        },
                    ],
                },
            ],
            themes: [],
            themeIds: undefined,
            taxWageExemption: undefined,
            budgetTypes: BudgetType.Employee,
            externalOrderUrl: undefined,
            externalOrderEmailAddress: undefined,
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    summary: '',
                    description: '',
                    url: '',
                    location: '',
                    durationAmount: 0,
                    durationUnit: '',
                },
            ],
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: GetAllInterventionInput) {
        const result = await InterventionService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.interventions = result;
        });
    }

    @action
    async getAllPublic(pagedFilterAndSortedRequest: GetAllInterventionInput) {
        const result = await InterventionService.getAllPublic(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.publicInterventions = result;
        });
    }

    @action
    async getStatistics(input: InterventionStatisticsInput) {
        const result = await InterventionService.getStatistics(input);
        runInAction(() => {
            this.interventionStatisticsAvailable = result.statisticsAvailable;
            this.interventionStatistics = result.statistics;
            this.orderDevelopment = result.orderDevelopment;
        });
    }
}

export default InterventionStore;
