const AppConsts = {
    userManagement: {
        defaultAdminUserName: 'admin',
    },
    localization: {
        defaultLocalizationSourceName: 'LekkerBezig',
    },
    authorization: {
        encrptedAuthTokenName: 'enc_auth_token',
    },
    appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
    remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
    branchBaseUrl: process.env.REACT_APP_BRANCH_LINK_BASE_URL,
    appVersionKey: 'lekker-bezig-app-version',
    metaJson: '/meta.json',
};
export default AppConsts;
