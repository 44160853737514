import http from '../httpService';
import { GetInterventionThemeOutput } from './dto/getInterventionThemeOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';

class InterventionThemeService {
  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<GetInterventionThemeOutput>> {
    const result = await http.get('api/services/app/InterventionTheme/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }
}

export default new InterventionThemeService();
