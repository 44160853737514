import { action, makeObservable, observable, runInAction } from 'mobx';

import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateThemeInput } from 'src/services/theme/dto/createOrUpdateThemeInput';
import type { GetThemeOutput } from 'src/services/theme/dto/getThemeOutput';
import type { UpdateThemeInput } from 'src/services/theme/dto/updateThemeInput';
import ThemeService from 'src/services/theme/themeService';

class ThemeStore {
    @observable themes: PagedResultDto<GetThemeOutput>;
    @observable editTheme: GetThemeOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createThemeInput: CreateOrUpdateThemeInput) {
        const result = await ThemeService.create(createThemeInput);
        runInAction(() => {
            this.themes.items.push(result);
        });
    }

    @action
    async update(updateThemeInput: UpdateThemeInput) {
        const result = await ThemeService.update(updateThemeInput);
        runInAction(() => {
            this.themes.items = this.themes.items.map((x: GetThemeOutput) => {
                if (x.id === updateThemeInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto) {
        await ThemeService.delete(entityDto);
        runInAction(() => {
            this.themes.items = this.themes.items.filter((x: GetThemeOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto) {
        const result = await ThemeService.get(entityDto);
        runInAction(() => {
            this.editTheme = result;
        });
    }

    @action
    createTheme() {
        this.editTheme = {
            title: '',
            description: '',
            localizations: [
                {
                    id: undefined,
                    parentId: 0,
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    description: '',
                },
            ],
            order: 0,
            id: 0,
            scorable: false,
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await ThemeService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.themes = result;
        });
    }
}

export default ThemeStore;
