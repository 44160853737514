import { action, makeObservable, observable, runInAction } from 'mobx';
import CarouselService from 'src/services/carousel/carouselService';
import type { GetCarouselOutput } from 'src/services/carousel/dto/getCarouselOutput';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';

class CarouselStore {
    @observable carousels: PagedResultDto<GetCarouselOutput>;
    @observable editCarousel: GetCarouselOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createCarouselInput: GetCarouselOutput) {
        const result = await CarouselService.create(createCarouselInput);
        runInAction(() => {
            this.carousels.items.push(result);
        });
    }

    @action
    async update(updateCarouselInput: GetCarouselOutput) {
        const result = await CarouselService.update(updateCarouselInput);
        runInAction(() => {
            this.carousels.items = this.carousels.items.map((x: GetCarouselOutput) => {
                if (x.id === updateCarouselInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await CarouselService.delete(entityDto);
        runInAction(() => {
            this.carousels.items = this.carousels.items.filter((x: GetCarouselOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await CarouselService.get(entityDto);
        runInAction(() => {
            this.editCarousel = result;
        });
    }

    @action
    createCarousel() {
        this.editCarousel = {
            id: '',
            title: '',
            content: '',
            backgroundImageUrl: '',
            buttonUrl: '',
            buttonText: '',
            analyticsSlug: '',
            order: 0,
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    content: '',
                    backgroundImageUrl: '',
                    buttonUrl: '',
                    buttonText: '',
                },
            ],
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await CarouselService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.carousels = result;
        });
    }
}

export default CarouselStore;
