import { UpdateThemeInput } from './dto/updateThemeInput';
import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { CreateOrUpdateThemeInput } from './dto/createOrUpdateThemeInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { GetThemeOutput } from './dto/getThemeOutput';

class ThemeService {
    public async create(createThemeInput: CreateOrUpdateThemeInput) {
        const result = await http.post('api/services/app/Theme/Create', createThemeInput);
        return result.data.result;
    }

    public async update(updateThemeInput: UpdateThemeInput) {
        const result = await http.put('api/services/app/Theme/Update', updateThemeInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto) {
        const result = await http.delete('api/services/app/Theme/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto): Promise<GetThemeOutput> {
        const result = await http.get('api/services/app/Theme/Get', { params: entityDto });
        return result.data.result;
    }

    public async getAll(
        pagedFilterAndSortedRequest: PagedFilterAndSortedRequest
    ): Promise<PagedResultDto<GetThemeOutput>> {
        const result = await http.get('api/services/app/Theme/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }
}

export default new ThemeService();
