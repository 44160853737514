import { action, makeObservable, observable, runInAction } from 'mobx';
import { AnswerType } from 'src/models/Questions/AnswerType';
import { QuestionType } from 'src/models/Questions/QuestionType';
import SurveyStatus from 'src/models/Survey/SurveyStatus';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateQuestionInput } from 'src/services/question/dto/createOrUpdateQuestionInput';
import type { CreateOrUpdateQuestionIntroInput } from 'src/services/question/dto/createOrUpdateQuestionIntroInput';
import type { GetAllQuestionsRequest } from 'src/services/question/dto/getAllQuestionsRequest';
import { GetQuestionOutput } from 'src/services/question/dto/getQuestionOutput';
import QuestionService from 'src/services/question/questionService';

class QuestionStore {
    @observable questions: PagedResultDto<GetQuestionOutput>;
    @observable surveyStatus: SurveyStatus;
    @observable surveyId: string;
    @observable editQuestion: CreateOrUpdateQuestionInput;
    @observable editQuestionIntro: CreateOrUpdateQuestionIntroInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createQuestionInput: CreateOrUpdateQuestionInput) {
        const result = await QuestionService.create(createQuestionInput);
        runInAction(() => {
            this.questions.items.push(result);
        });
    }

    @action
    async createQuestionIntro(createQuestionIntroInput: CreateOrUpdateQuestionIntroInput) {
        const result = await QuestionService.createQuestionIntro(createQuestionIntroInput);
        runInAction(() => {
            this.questions.items.push(result);
        });
    }

    @action
    async update(updateQuestionInput: CreateOrUpdateQuestionInput) {
        if (updateQuestionInput.answerType !== AnswerType.Scale) {
            updateQuestionInput.valueInverted = false;
        }

        const result = await QuestionService.update(updateQuestionInput);
        runInAction(() => {
            this.questions.items = this.questions.items.map((x: GetQuestionOutput) => {
                if (x.id === updateQuestionInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await QuestionService.delete(entityDto);
        runInAction(() => {
            this.questions.items = this.questions.items.filter((x: GetQuestionOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await QuestionService.get(entityDto);
        runInAction(() => {
            this.editQuestion = result;
        });
    }

    @action
    async getQuestionIntro(entityDto: EntityDto<string>) {
        const result = await QuestionService.getIntro(entityDto);
        runInAction(() => {
            this.editQuestionIntro = result;
        });
    }

    @action
    createQuestion() {
        this.editQuestion = {
            categoryId: undefined,
            questionType: QuestionType.Regular,
            answerType: undefined,
            introType: undefined,
            minValue: undefined,
            maxValue: undefined,
            choices: undefined,
            valueInverted: false,
            order: 0,
            id: '',
            surveyId: '',
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    text: '',
                    description: '',
                    hint: '',
                    minDescription: '',
                    maxDescription: '',
                },
            ],
        };
    }

    @action
    createIntro() {
        this.editQuestionIntro = {
            questionType: QuestionType.Intro,
            introType: undefined,
            order: 0,
            id: '',
            surveyId: '',
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    text: '',
                    description: '',
                },
            ],
        };
    }

    @action
    async getAll(getAllQuestionsRequest: GetAllQuestionsRequest) {
        const result = await QuestionService.getAll(getAllQuestionsRequest);
        runInAction(() => {
            this.questions = result;
        });
    }
}

export default QuestionStore;
