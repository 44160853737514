import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateSurveyInput } from 'src/services/survey/dto/createOrUpdateSurveyInput';
import type { GetSurveyOutput } from 'src/services/survey/dto/getSurveyOutput';
import surveyService from 'src/services/survey/surveyService';

class SurveyStore {
    @observable surveys: PagedResultDto<GetSurveyOutput>;
    @observable survey: GetSurveyOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await surveyService.get(entityDto);
        runInAction(() => {
            this.survey = result;
        });
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await surveyService.getAllSurvey(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.surveys = result;
        });
    }

    @action
    async create(updateOrUpdateSurveyInput: CreateOrUpdateSurveyInput) {
        const result = await surveyService.create(updateOrUpdateSurveyInput);
        runInAction(() => {
            this.surveys.items.push(result);
        });
    }

    @action
    async update(updateOrUpdateSurveyInput: CreateOrUpdateSurveyInput) {
        const result = await surveyService.update(updateOrUpdateSurveyInput);

        runInAction(() => {
            this.surveys.items = this.surveys.items.map((x: GetSurveyOutput) => {
                if (x.id === updateOrUpdateSurveyInput.id) {
                    x = result;
                }
                return x;
            });
        });
    }

    @action
    async activateSurvey(entityDto: EntityDto<string>) {
        const result = await surveyService.activateSurvey(entityDto);
        runInAction(() => {
            this.survey = result;
        });
    }

    @action
    async duplicateSurvey(entityDto: EntityDto<string>) {
        const result = await surveyService.getDuplicateSurvey(entityDto);
        runInAction(() => {
            this.survey = result;
            this.surveys.items.unshift(result);
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await surveyService.deleteSurvey(entityDto);
        runInAction(() => {
            this.surveys.items = this.surveys.items.filter((x: GetSurveyOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async getLatestSurvey() {
        this.survey = await surveyService.getLatestSurvey();
    }

    @action
    createSurvey() {
        this.survey = {
            id: undefined,
            name: '',
            isDraft: undefined,
            isActive: undefined,
            publishedDate: undefined,
            creationTime: undefined,
            surveyStatus: undefined,
        };
    }
}

export default SurveyStore;
