import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { GetSurveySubmissionOutput } from 'src/services/survey/dto/getSurveySubmissionOutput';
import type SurveySubmissionStatisticsOutput from 'src/services/survey/dto/statistics/SurveySubmissionStatisticsOutput';
import type SurveySubmissionStatisticsRequest from 'src/services/survey/dto/statistics/SurveySubmissionStatisticsRequest';
import surveySubmissionService from 'src/services/survey/surveySubmissionService';

class SurveySubmissionStore {
    @observable editSurvey: GetSurveySubmissionOutput;
    @observable employeeHasSurveySubmission: boolean | undefined;
    @observable surveySubmissionStatistics: SurveySubmissionStatisticsOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await surveySubmissionService.get(entityDto);
        runInAction(() => {
            this.employeeHasSurveySubmission = result !== null && result !== undefined;
            this.editSurvey = result;
        });
    }

    @action
    async getByEmployee(entityDto: EntityDto<string>) {
        const result = await surveySubmissionService.getByEmployee(entityDto);
        runInAction(() => {
            this.employeeHasSurveySubmission = result !== null && result !== undefined;
            this.editSurvey = result;
        });
    }

    @action
    async getStatistics(request: SurveySubmissionStatisticsRequest) {
        const result = await surveySubmissionService.getStatistics(request);
        runInAction(() => {
            this.surveySubmissionStatistics = result;
        });
    }
}

export default SurveySubmissionStore;
