import { action, makeObservable } from 'mobx';
import FeedbackService from 'src/services/feedback/feedbackService';
import type { BaseFeedbackInput, LekkerBezigFeedbackInput } from './../services/feedback/dto/FeedbackInput';

class LekkerBezigStore {
    constructor() {
        makeObservable(this);
    }

    @action
    async submitLekkerBezigFeedbackDone(input: LekkerBezigFeedbackInput) {
        await FeedbackService.submitLekkerBezigFeedback(input);
    }

    @action
    async submitLekkerBezigFeedbackOpen(input: BaseFeedbackInput) {
        await FeedbackService.submitLekkerBezigFeedbackOpen(input);
    }
}

export default LekkerBezigStore;
