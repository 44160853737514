import { LekkerBezigFeedbackInput, BaseFeedbackInput } from './dto/FeedbackInput';
import http from '../httpService';

class FeedbackService {
  public async submitLekkerBezigFeedback(input: LekkerBezigFeedbackInput) {
    const result = await http.post('api/services/app/Feedback/CreateLekkerBezigFeedback', input);
    return result.data.result;
  }

  public async submitLekkerBezigFeedbackOpen(input: BaseFeedbackInput) {
    const result = await http.post('api/services/app/Feedback/CreateLekkerBezigFeedbackOpen', input);
    return result.data.result;
  }
}

export default new FeedbackService();
