import http from '../httpService';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { TaxWageSurveyDto } from './dto/taxWageSurveyDto';
import TaxWageSurveySubmissionExportRequestDto from './dto/taxWageSurveySubmissionExportRequestDto';

class TaxWageSurveyService {
    public async getAll(): Promise<PagedResultDto<TaxWageSurveyDto>> {
        const result = await http.get('api/services/app/TaxWageSurvey/GetAll');
        return result.data.result;
    }

    public async getExport(exportRequest: TaxWageSurveySubmissionExportRequestDto) {
        const result = await http.get('api/services/app/TaxWageSurveySubmission/GetExport', {
            params: exportRequest,
            responseType: 'arraybuffer',
        });
        return result;
    }
}

export default new TaxWageSurveyService();
