import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MaintenanceLayout } from 'src/components/Layout/MaintenanceLayout';
import utils from 'src/utils/utils';
import ProtectedRoute from './ProtectedRoute';

const Router = () => {
    const UserLayout = utils.getRoute('/user').component;
    const PageLayout = utils.getRoute('/p').component;
    const BranchLayout = utils.getRoute('/b').component;
    const AppLayout = utils.getRoute('/').component;

    if (process.env.REACT_APP_MAINTENANCE_ENABLED === '1') {
        return <Route path="*" render={() => <MaintenanceLayout />} />;
    }

    return (
        <Switch>
            <Route path="/user" render={(props: any) => <UserLayout {...props} />} />
            <Route path="/p" render={(props: any) => <PageLayout {...props} />} />
            <Route path="/b" render={(props: any) => <BranchLayout {...props} />} />
            <ProtectedRoute path="/" render={(props: any) => <AppLayout {...props} exact={true} />} />
        </Switch>
    );
};

export default Router;
