import { AuthenticationModel } from './dto/authenticationModel';
import { AuthenticationResultModel } from './dto/authenticationResultModel';
import { TwoFactorAuthenticationModel } from './dto/twoFactorAuthenticationModel';
import http from '../httpService';
import { RefreshTokenModel } from './dto/refreshTokenModel';

class TokenAuthService {
    public async authenticate(authenticationInput: AuthenticationModel): Promise<AuthenticationResultModel> {
        const result = await http.post('api/TokenAuth/Authenticate', authenticationInput);
        return result.data.result;
    }

    public async twoFactorAuthenticate(
        authenticationInput: TwoFactorAuthenticationModel
    ): Promise<AuthenticationResultModel> {
        const result = await http.post('api/TokenAuth/AuthenticateTwoFactor', authenticationInput);
        return result.data.result;
    }

    public async refreshToken(authenticationInput: RefreshTokenModel): Promise<AuthenticationResultModel> {
        const result = await http.post('api/TokenAuth/AuthenticateByRefreshToken', authenticationInput);
        return result.data.result;
    }

    public async impersonateUser(authenticationInput: { userId: number }): Promise<AuthenticationResultModel> {
        const result = await http.post('api/TokenAuth/ImpersonateUser', authenticationInput);
        return result.data.result;
    }

    public async logout(): Promise<boolean> {
        const result = await http.post('api/TokenAuth/Logout');
        return result.data.result;
    }
}

export default new TokenAuthService();
