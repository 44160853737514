import { action, makeObservable, observable, runInAction } from 'mobx';
import accountService from 'src/services/account/accountService';
import IsTenantAvaibleOutput from 'src/services/account/dto/isTenantAvailableOutput';

class AccountStore {
    @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();

    @action
    public isTenantAvailable = async (tenancyName: string) => {
        const res = await accountService.isTenantAvailable({ tenancyName });
        runInAction(() => {
            this.tenant = res;
        });
    };

    constructor() {
        makeObservable(this);
    }
}

export default AccountStore;
