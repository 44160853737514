import { action, makeObservable, observable } from 'mobx';
import LoginTarget from './loginTarget';

class LoginModel {
    tenancyName: string;
    userNameOrEmailAddress: string;
    password: string;
    target: LoginTarget;
    rememberMe: boolean;

    constructor() {
        makeObservable(this, {
            rememberMe: observable,
            toggleRememberMe: action,
        });
        this.rememberMe = process.env.NODE_ENV === 'development';
    }

    toggleRememberMe = () => {
        this.rememberMe = !this.rememberMe;
    };
}

export default LoginModel;
