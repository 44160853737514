import { action, makeObservable, observable, runInAction } from 'mobx';
import { PushNotificationCategory } from 'src/models/PushNotification/PushNotificationCategory';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdatePushNotificationInput } from 'src/services/pushNotification/dto/createOrUpdatePushNotificationInput';
import { GetPushNotificationOutput } from 'src/services/pushNotification/dto/getPushNotificationOutput';
import PushNotificationService from 'src/services/pushNotification/pushNotificationService';

class PushNotificationStore {
    @observable pushNotifications: PagedResultDto<GetPushNotificationOutput>;
    @observable editPushNotification: CreateOrUpdatePushNotificationInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createPushNotificationInput: CreateOrUpdatePushNotificationInput) {
        const result = await PushNotificationService.create(createPushNotificationInput);
        runInAction(() => {
            this.pushNotifications.items.push(result);
        });
    }

    @action
    async update(updatePushNotificationInput: CreateOrUpdatePushNotificationInput) {
        const result = await PushNotificationService.update(updatePushNotificationInput);
        runInAction(() => {
            this.pushNotifications.items = this.pushNotifications.items.map((x: GetPushNotificationOutput) => {
                if (x.id === updatePushNotificationInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await PushNotificationService.delete(entityDto);
        runInAction(() => {
            this.pushNotifications.items = this.pushNotifications.items.filter(
                (x: GetPushNotificationOutput) => x.id !== entityDto.id
            );
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await PushNotificationService.get(entityDto);
        runInAction(() => {
            this.editPushNotification = result;
        });
    }

    @action
    createPushNotification() {
        this.editPushNotification = {
            id: '',
            identifier: '',
            category: PushNotificationCategory.StartSurvey,
            afterDays: 0,
            beforeDays: 0,
            title: 'Lekker Bezig',
            text: '',
            isActive: false,
            sendIndividually: false,
            repeats: false,
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await PushNotificationService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.pushNotifications = result;
        });
    }
}

export default PushNotificationStore;
