import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { GetPageOutput } from 'src/services/page/dto/getPageOutput';
import PageService from 'src/services/page/pageService';

class PageStore {
    @observable pages: PagedResultDto<GetPageOutput>;
    @observable editPage: GetPageOutput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createPageInput: GetPageOutput) {
        const result = await PageService.create(createPageInput);
        runInAction(() => {
            this.pages.items.push(result);
        });
    }

    @action
    async update(updatePageInput: GetPageOutput) {
        const result = await PageService.update(updatePageInput);
        runInAction(() => {
            this.pages.items = this.pages.items.map((x: GetPageOutput) => {
                if (x.id === updatePageInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await PageService.delete(entityDto);
        runInAction(() => {
            this.pages.items = this.pages.items.filter((x: GetPageOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await PageService.get(entityDto);
        runInAction(() => {
            this.editPage = result;
        });
    }

    @action
    createPage() {
        this.editPage = {
            id: '',
            title: '',
            content: '',
            identifier: '',
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    identifier: '',
                    title: '',
                    content: '',
                },
            ],
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await PageService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.pages = result;
        });
    }
}

export default PageStore;
