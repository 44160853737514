import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateFeaturedInterventionDto } from 'src/services/featuredIntervention/dto/CreateOrUpdateFeaturedInterventionDto​';
import type { GetAllFeaturedInterventionsRequest } from 'src/services/featuredIntervention/dto/GetAllFeatureInterventionsRequest';
import featuredInterventionsService from 'src/services/featuredIntervention/featuredIntervetionService';

class FeaturedInterventionStore {
    @observable featuredInterventions: PagedResultDto<CreateOrUpdateFeaturedInterventionDto>;
    @observable editFeaturedIntervention: CreateOrUpdateFeaturedInterventionDto;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createInput: CreateOrUpdateFeaturedInterventionDto) {
        await featuredInterventionsService.create(createInput);
    }

    @action
    createFeaturedIntervention() {
        this.editFeaturedIntervention = {
            id: '',
            order: 0,
            interventionId: '',
            interventionTitle: '',
            localeId: '',
        };
    }

    @action
    async update(updateInput: CreateOrUpdateFeaturedInterventionDto) {
        const result = await featuredInterventionsService.update(updateInput);

        runInAction(() => {
            this.featuredInterventions.items = this.featuredInterventions.items.map(
                (x: CreateOrUpdateFeaturedInterventionDto) => {
                    if (x.id === updateInput.id) {
                        x = result;
                    }
                    return x;
                }
            );
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await featuredInterventionsService.delete(entityDto);
        runInAction(() => {
            this.featuredInterventions.items = this.featuredInterventions.items.filter(
                (x: CreateOrUpdateFeaturedInterventionDto) => x.id !== entityDto.id
            );
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await featuredInterventionsService.get(entityDto);
        runInAction(() => {
            this.editFeaturedIntervention = result;
        });
    }

    @action
    async getAll(pagedFilterAndSortedRequest: GetAllFeaturedInterventionsRequest) {
        const result = await featuredInterventionsService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.featuredInterventions = result;
        });
    }
}

export default FeaturedInterventionStore;
