import { CreateOrUpdatePushNotificationInput } from './dto/createOrUpdatePushNotificationInput';
import http from '../httpService';
import { GetPushNotificationOutput } from './dto/getPushNotificationOutput';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityDto } from '../dto/entityDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';

class PushNotificationService {
  public async create(createPushNotificationInput: CreateOrUpdatePushNotificationInput) {
    const result = await http.post('api/services/app/PushNotification/Create', createPushNotificationInput);
    return result.data.result;
  }

  public async update(updatePushNotificationInput: CreateOrUpdatePushNotificationInput) {
    const result = await http.put('api/services/app/PushNotification/Update', updatePushNotificationInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto<string>) {
    const result = await http.delete('api/services/app/PushNotification/Delete', { params: entityDto });
    return result.data;
  }

  public async get(entityDto: EntityDto<string>): Promise<GetPushNotificationOutput> {
    const result = await http.get('api/services/app/PushNotification/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<GetPushNotificationOutput>> {
    const result = await http.get('api/services/app/PushNotification/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }
}

export default new PushNotificationService();
