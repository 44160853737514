import * as Icons from '@ant-design/icons/lib';
import React from 'react';
import { L } from 'src/lib/abpUtility';
import { LazyComponent } from './../Loadable/index';
import RouteModel from './routeModel';

export const userRouter: RouteModel[] = [
    {
        path: '/user',
        name: 'user',
        title: 'User',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/UserLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/user/login',
        name: 'login',
        title: 'LogIn',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Login'), props),
        showInMenu: false,
    },
    {
        path: '/user/forgot',
        name: 'forgotPassword',
        title: 'PasswordForgot',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Password/Forgot'), props),
        showInMenu: false,
    },
    {
        path: '/user/reset',
        name: 'passwordReset',
        title: 'PasswordReset',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Password/Reset'), props),
        showInMenu: false,
    },
    {
        path: '/user/feedback/order-started',
        name: 'feedbackOrderStarted',
        title: 'FeedbackOrderStarted',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Feedback/OrderStarted'), props),
        showInMenu: false,
    },
    {
        path: '/user/feedback/order-not-started',
        name: 'feedbackOrderNotStarted',
        title: 'FeedbackOrderNotStarted',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Feedback/OrderNotStarted'), props),
        showInMenu: false,
    },
    {
        path: '/user/feedback/order-done',
        name: 'feedbackOrder',
        title: 'FeedbackOrder',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Feedback/Rating'), props),
        showInMenu: false,
    },
    {
        path: '/user/feedback/lekkerBezig',
        name: 'feedbackLekkerBezig',
        title: 'FeedbackLekkerBezig',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Feedback/Rating'), props),
        showInMenu: false,
    },
    {
        path: '/user/activate',
        name: 'passwordActivate',
        title: 'PasswordActivate',
        icon: <Icons.UserOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Password/Reset'), { ...props, activate: true }),
        showInMenu: false,
    },
];

export const pageRouter: RouteModel[] = [
    {
        path: '/p',
        name: 'page',
        title: 'Page',
        icon: <Icons.FileTextOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/PageLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
];

export const branchRouter: RouteModel[] = [
    {
        path: '/b',
        name: 'branch',
        title: 'Branch',
        icon: <Icons.LinkOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/BranchLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/b/qr',
        name: 'branch',
        title: 'Branch',
        icon: <Icons.LinkOutlined />,
        component: (props) => LazyComponent(() => import('src/scenes/Branch'), props),
        showInMenu: false,
    },
];

export const managerRouters: RouteModel[] = [
    {
        path: '/',
        exact: true,
        name: 'home',
        permission: '',
        title: 'Home',
        icon: <Icons.HomeOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/AppLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        title: 'Dashboard',
        icon: <Icons.RiseOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Dashboard'), props),
    },
    {
        path: '/business-structure',
        name: 'business-structure',
        title: 'BusinessStructure',
        permission: 'Manager.Admin',
        icon: <Icons.PartitionOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/BusinessUnits'), props),
    },
    {
        path: '/employer-groups',
        permission: 'UserProfiles.EmployerGroups',
        title: 'Groups',
        name: 'EmployerGroups',
        icon: <Icons.TeamOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/EmployerGroups'), props),
    },
    {
        path: '/employers-accounts',
        permission: 'UserProfiles.EmployerAccounts',
        title: 'EmployerAccounts',
        name: 'EmployerAccounts',
        icon: <Icons.UserOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/EmployerAccounts'), props),
    },
    {
        path: '/employees',
        permission: 'UserProfiles.Employees',
        title: 'Employees',
        name: 'employees',
        icon: <Icons.UserOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Employees'), props),
    },
    {
        path: '/budget',
        permission: 'Budget',
        title: 'Budget',
        name: 'budget',
        icon: <Icons.EuroOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Budget'), props),
    },
    {
        path: '/budget-adjust',
        permission: 'Budget.UpdateAllEmployees',
        title: 'AdjustEmployeeBudget',
        name: 'AdjustEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AdjustBudget'), props),
    },
    {
        path: '/budget-export',
        permission: 'Budget.EmployeeBudgets',
        title: 'EmployeeBudgetPerEmployee',
        name: 'ExportEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportBudget'), props),
    },
    {
        path: '/budget-mutations-export',
        permission: 'Budget.EmployeeSpending',
        title: 'EmployeeSpendingPerEmployee',
        name: 'ExportMutations',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportMutations'), props),
    },
    {
        path: '/budget-spending-export',
        permission: 'Budget.EmployeeSpending',
        title: 'EmployeeSpendingPerEmployee',
        name: 'ExportEmployeeSpending',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportSpending'), props),
    },
    {
        path: '/budget-import',
        permission: 'Budget.ImportBudgetForEmployees',
        title: 'ImportBudgetPerEmployee',
        name: 'ImportEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ImportBudget'), props),
    },
    {
        path: '/budget-mutate',
        permission: 'Budget.ImportBudgetForEmployees',
        title: 'ProcessBudgetMutations',
        name: 'ProcessBudgetMutations',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/MutateBudget'), props),
    },
    {
        path: '/budget-auto-increase',
        permission: 'Budget.UpdateAllEmployees',
        title: 'AutoIncreaseBudget',
        name: 'AutoIncreaseBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AutoIncreaseBudget'), props),
    },
    {
        path: '/order-overview',
        permission: 'OrderOverview',
        title: 'OrderOverviewMenu',
        name: 'order-overview',
        icon: <Icons.ShoppingCartOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/OrderOverview'), props),
    },
    {
        path: '/toolkit',
        name: 'toolkit',
        title: 'ToolkitMenu',
        permission: 'Toolkit',
        icon: <Icons.BulbOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Toolkit'), props),
    },
    {
        path: '/logout',
        permission: '',
        title: 'Logout',
        name: 'logout',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/components/Logout'), props),
    },
    {
        path: '/interventions-public',
        title: 'Interventions',
        name: 'interventions',
        icon: <Icons.ShopOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/PublicInterventions'), props),
    },
];

export const appRouters: RouteModel[] = [
    {
        path: '/',
        exact: true,
        name: 'home',
        permission: '',
        title: 'Home',
        icon: <Icons.HomeOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/AppLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        title: 'Dashboard',
        icon: <Icons.RiseOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Dashboard'), props),
    },
    {
        path: '/business-structure',
        name: 'business-structure',
        title: 'BusinessStructure',
        permission: 'Manager.Admin',
        icon: <Icons.PartitionOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/BusinessUnits'), props),
    },
    {
        name: 'clients',
        title: 'Clients',
        permission: 'UserProfiles',
        icon: <Icons.TeamOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/employers',
                permission: 'UserProfiles.Employers',
                title: 'Employers',
                name: 'employers',
                icon: <Icons.CrownOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Employers'), props),
            },
            {
                path: '/employers-accounts',
                permission: 'UserProfiles.EmployerAccounts',
                title: 'EmployerAccounts',
                name: 'EmployerAccounts',
                icon: <Icons.UserOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/EmployerAccounts'), props),
            },
            {
                path: '/employer-groups',
                permission: 'UserProfiles.EmployerGroups',
                title: 'Groups',
                name: 'EmployerGroups',
                icon: <Icons.TeamOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/EmployerGroups'), props),
            },
            {
                path: '/budget',
                permission: 'Budget',
                title: 'Budget',
                name: 'budget',
                icon: <Icons.EuroOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Budget'), props),
            },
            {
                path: '/budget-adjust',
                permission: 'Budget.UpdateAllEmployees',
                title: 'AdjustEmployeeBudget',
                name: 'AdjustEmployeeBudget',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AdjustBudget'), props),
            },
            {
                path: '/budget-export',
                permission: 'Budget.EmployeeBudgets',
                title: 'EmployeeBudgetPerEmployee',
                name: 'ExportEmployeeBudget',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportBudget'), props),
            },
            {
                path: '/budget-mutations-export',
                permission: 'Budget.EmployeeSpending',
                title: 'EmployeeSpendingPerEmployee',
                name: 'ExportMutations',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportMutations'), props),
            },
            {
                path: '/budget-spending-export',
                permission: 'Budget.EmployeeSpending',
                title: 'EmployeeSpendingPerEmployee',
                name: 'ExportEmployeeSpending',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportSpending'), props),
            },
            {
                path: '/budget-import',
                permission: 'Budget.ImportBudgetForEmployees',
                title: 'ImportBudgetPerEmployee',
                name: 'ImportEmployeeBudget',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ImportBudget'), props),
            },
            {
                path: '/budget-mutate',
                permission: 'Budget.ImportBudgetForEmployees',
                title: 'ProcessBudgetMutations',
                name: 'ProcessBudgetMutations',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/MutateBudget'), props),
            },
            {
                path: '/budget-auto-increase',
                permission: 'Budget.UpdateAllEmployees',
                title: 'AutoIncreaseBudget',
                name: 'AutoIncreaseBudget',
                icon: <Icons.EuroOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AutoIncreaseBudget'), props),
            },
            {
                path: '/totalbudget',
                permission: 'Employer.UpdateTotalBudget',
                title: 'TotalBudget',
                name: 'totalBudget',
                icon: <Icons.EuroOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/TotalBudget'), props),
            },
            {
                path: '/employees',
                permission: 'UserProfiles.Employees',
                title: 'Employees',
                name: 'employees',
                icon: <Icons.UserOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Employees'), props),
            },
        ],
    },
    {
        path: '/toolkit',
        name: 'toolkit',
        title: 'ToolkitMenu',
        permission: 'Toolkit',
        icon: <Icons.BulbOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Toolkit'), props),
    },
    {
        name: 'questionnaires',
        title: 'Questionnaires',
        permission: 'Questionnaires',
        icon: <Icons.FormOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/surveys',
                permission: 'Questionnaires.Survey.GetAll',
                title: 'Surveys',
                name: 'surveys',
                icon: <Icons.FileSearchOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Surveys'), props),
            },
            {
                path: '/themes',
                permission: 'Questionnaires.Themes',
                title: 'Themes',
                name: 'themes',
                icon: <Icons.StarOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Themes'), props),
            },
            {
                path: '/questions',
                permission: 'Questionnaires.Questions',
                title: 'Questions',
                name: 'questions',
                icon: <Icons.QuestionOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Questions'), props),
            },
        ],
    },
    {
        name: 'shop',
        title: 'Shop',
        permission: 'Shop',
        icon: <Icons.ShopOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/interventions',
                permission: 'Shop.Interventions',
                title: 'Interventions',
                name: 'interventions',
                icon: <Icons.RocketOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Interventions'), props),
            },
            {
                path: '/interventions-public',
                permission: 'Public.Interventions',
                title: 'Interventions',
                name: 'interventions',
                icon: <Icons.ShopOutlined />,
                showInMenu: false,
                component: (props) => LazyComponent(() => import('src/scenes/PublicInterventions'), props),
            },
            {
                path: '/interventions-change-requests',
                permission: 'Shop.Interventions.ManageOwnSupplyRequests',
                title: 'HandleInterventionChangeRequests',
                name: 'HandleInterventionChangeRequests',
                icon: <Icons.ContainerOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/InterventionChangeRequests'), props),
            },
            {
                path: '/featured-interventions',
                permission: 'FeaturedInterventions.GetAll',
                title: 'FeaturedInterventions',
                name: 'featured-interventions',
                icon: <Icons.StarOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/FeaturedInterventions'), props),
            },
            {
                path: '/external-feed',
                permission: 'DataFeed',
                title: 'ExternalFeed',
                name: 'ExternalFeed',
                icon: <Icons.ImportOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/ExternalFeed'), props),
            },
            {
                path: '/order-overview',
                permission: 'OrderOverview',
                title: 'OrderOverview',
                name: 'order-overview',
                icon: <Icons.ShoppingCartOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/OrderOverview'), props),
            },
            {
                path: '/orders',
                permission: 'Shop.Orders',
                title: 'Orders',
                name: 'orders',
                icon: <Icons.SolutionOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Orders'), props),
            },
            {
                path: '/manage-supply',
                permission: 'Shop.Suppliers',
                title: 'ManageSupply',
                name: 'ManageSupply',
                icon: <Icons.ShopOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/ManageSupply'), props),
            },
            {
                path: '/suppliers',
                permission: 'Shop.Suppliers',
                title: 'Suppliers',
                name: 'suppliers',
                icon: <Icons.ShoppingOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Suppliers'), props),
            },
        ],
    },
    {
        name: 'administration',
        title: 'Administration',
        permission: 'Administration',
        icon: <Icons.SettingOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/users',
                permission: 'Administration.Users',
                title: 'Users',
                name: 'user',
                icon: <Icons.UserOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Users'), props),
            },
            {
                path: '/roles',
                permission: 'Administration.Roles.GetAll',
                title: 'Roles',
                name: 'role',
                icon: <Icons.TagsOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Roles'), props),
            },
            {
                path: '/authorization-matrix',
                permission: 'Administration.AuthorizationMatrix',
                title: 'AuthorizationMatrix',
                name: 'authorization-matrix',
                icon: <Icons.TableOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Roles/AuthorizationMatrix'), props),
            },
        ],
    },
    {
        name: 'content',
        title: 'Content',
        permission: 'Content',
        icon: <Icons.ContainerOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/carousels',
                permission: 'Content.Carousels',
                title: 'Carousels',
                name: 'carousels',
                icon: <Icons.FileTextOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Carousel'), props),
            },
            {
                path: '/pages',
                permission: 'Content.Pages',
                title: 'Pages',
                name: 'pages',
                icon: <Icons.FileTextOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Page'), props),
            },
            {
                path: '/articles',
                permission: 'Content.Articles',
                title: 'Articles',
                name: 'articles',
                icon: <Icons.FormOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/Articles'), props),
            },
            {
                path: '/faqcategory',
                permission: 'Faq',
                title: L('FaqCategories'),
                name: 'categories',
                icon: <Icons.StarOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/FaqCategory'), props),
            },
            {
                path: '/faq',
                permission: 'Faq',
                title: L('FaqQuestions'),
                name: 'questions',
                icon: <Icons.QuestionOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/FaqQuestion'), props),
            },
            {
                path: '/push-notifications',
                permission: 'Content.PushNotifications',
                title: 'PushNotifications',
                name: 'push-notifications',
                icon: <Icons.NotificationOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/PushNotifications'), props),
            },
        ],
    },
    {
        path: '/logout',
        permission: '',
        title: 'Logout',
        name: 'logout',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/components/Logout'), props),
    },
    {
        path: '/exception',
        permission: '',
        title: 'exception',
        name: 'exception',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Exception'), props),
    },
    {
        path: '/supplier-accounts',
        permission: 'UserProfiles.SupplierAccounts',
        title: 'SupplierAccounts',
        name: 'supplier-accounts',
        icon: <Icons.UserOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/SupplierAccount'), props),
    },
];

export const employerRouters: RouteModel[] = [
    {
        path: '/',
        exact: true,
        name: 'home',
        permission: '',
        title: 'Home',
        icon: <Icons.HomeOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/AppLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        title: 'Dashboard',
        icon: <Icons.RiseOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Dashboard'), props),
    },
    {
        path: '/employees',
        permission: 'UserProfiles.Employees',
        title: 'Employees',
        name: 'employees',
        icon: <Icons.TeamOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Employees'), props),
    },
    {
        path: '/employers-accounts',
        permission: 'UserProfiles.EmployerAccounts',
        title: 'EmployerAccounts',
        name: 'EmployerAccounts',
        icon: <Icons.UserOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/EmployerAccounts'), props),
    },
    {
        path: '/employer-groups',
        permission: 'UserProfiles.EmployerGroups',
        title: 'Groups',
        name: 'EmployerGroups',
        icon: <Icons.TeamOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/EmployerGroups'), props),
    },
    {
        path: '/order-overview',
        permission: 'OrderOverview',
        title: 'OrderOverviewMenu',
        name: 'order-overview',
        icon: <Icons.ShoppingCartOutlined />,
        showInMenu: true,
        sessionCondition: (session) =>
            session.currentLogin?.employer?.budgetExceedingOrdersEnabled ||
            session.currentLogin?.employer?.orderRequestRequired,
        component: (props) => LazyComponent(() => import('src/scenes/OrderOverview'), props),
    },
    {
        path: '/budget',
        permission: 'Budget',
        title: 'Budget',
        name: 'budget',
        icon: <Icons.EuroOutlined />,
        showInMenu: true,
        sessionCondition: (session) =>
            session.currentLogin?.employer?.budgetFeatures?.canBulkEditEmployeeBudget ||
            session.currentLogin?.employer?.budgetFeatures?.canEditEmployeeBudget ||
            session.currentLogin?.employer?.budgetFeatures?.canMutateEmployeeBudget ||
            session.currentLogin?.employer?.budgetFeatures?.canViewEmployeeBudget ||
            session.currentLogin?.employer?.budgetFeatures?.canViewEmployeeExpenses ||
            session.currentLogin?.employer?.budgetFeatures?.canAutomaticIncreaseEmployeeBudget,
        component: (props) => LazyComponent(() => import('src/scenes/Budget'), props),
    },
    {
        path: '/budget-adjust',
        permission: 'Budget.UpdateAllEmployees',
        title: 'AdjustEmployeeBudget',
        name: 'AdjustEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AdjustBudget'), props),
    },
    {
        path: '/budget-export',
        permission: 'Budget.EmployeeBudgets',
        title: 'EmployeeBudgetPerEmployee',
        name: 'ExportEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportBudget'), props),
    },
    {
        path: '/budget-mutations-export',
        permission: 'Budget.EmployeeSpending',
        title: 'EmployeeSpendingPerEmployee',
        name: 'ExportMutations',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportMutations'), props),
    },
    {
        path: '/budget-spending-export',
        permission: 'Budget.EmployeeSpending',
        title: 'EmployeeSpendingPerEmployee',
        name: 'ExportEmployeeSpending',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ExportSpending'), props),
    },
    {
        path: '/budget-import',
        permission: 'Budget.ImportBudgetForEmployees',
        title: 'ImportBudgetPerEmployee',
        name: 'ImportEmployeeBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/ImportBudget'), props),
    },
    {
        path: '/budget-mutate',
        permission: 'Budget.ImportBudgetForEmployees',
        title: 'ProcessBudgetMutations',
        name: 'ProcessBudgetMutations',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/MutateBudget'), props),
    },
    {
        path: '/budget-auto-increase',
        permission: 'Budget.UpdateAllEmployees',
        title: 'AutoIncreaseBudget',
        name: 'AutoIncreaseBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Budget/Tasks/AutoIncreaseBudget'), props),
    },
    {
        path: '/totalbudget',
        permission: 'Employer.UpdateTotalBudget',
        title: 'TotalBudget',
        name: 'totalBudget',
        icon: <Icons.EuroOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/TotalBudget'), props),
    },
    {
        name: 'interventions',
        title: 'Interventions',
        permission: 'Public.Interventions',
        icon: <Icons.ShopOutlined />,
        isSubMenu: true,
        showInMenu: true,
        routers: [
            {
                path: '/interventions-public',
                permission: 'Public.Interventions',
                title: 'Interventions',
                name: 'interventions',
                icon: <Icons.ShoppingOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/PublicInterventions'), props),
            },
            {
                path: '/interventions-change-requests',
                permission: 'Employer.Interventions.ManageOwnSupply',
                title: 'InterventionChangeRequests',
                name: 'InterventionChangeRequests',
                icon: <Icons.RocketOutlined />,
                showInMenu: true,
                component: (props) => LazyComponent(() => import('src/scenes/InterventionChangeRequests'), props),
            },
        ],
    },
    {
        path: '/toolkit',
        name: 'toolkit',
        title: 'ToolkitMenu',
        permission: 'Toolkit',
        icon: <Icons.BulbOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/Toolkit'), props),
    },
    {
        path: '/logout',
        permission: '',
        title: 'Logout',
        name: 'logout',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/components/Logout'), props),
    },
    {
        path: '/exception',
        permission: '',
        title: 'exception',
        name: 'exception',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Exception'), props),
    },
];

export const supplierRouters: RouteModel[] = [
    {
        path: '/',
        exact: true,
        name: 'home',
        permission: '',
        title: 'Home',
        icon: <Icons.HomeOutlined />,
        component: (props) => LazyComponent(() => import('src/components/Layout/AppLayout'), props),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: '/order-overview',
        permission: 'OrderOverview',
        title: 'OrderOverviewMenu',
        name: 'order-overview',
        icon: <Icons.ShoppingCartOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/OrderOverview'), props),
    },
    {
        path: '/orders-view',
        permission: 'OrderOverview',
        title: 'OrderStatus',
        name: 'OrderStatus',
        icon: <Icons.SolutionOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/OrdersView'), props),
    },
    {
        path: '/supplier-accounts',
        permission: 'UserProfiles.SupplierAccounts',
        title: 'SupplierAccountsMenu',
        name: 'supplier-accounts',
        icon: <Icons.UserOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/SupplierAccount'), props),
    },
    {
        path: '/interventions-change-requests',
        permission: 'Supplier.Interventions.ManageOwnSupply',
        title: 'InterventionChangeRequests',
        name: 'InterventionChangeRequests',
        icon: <Icons.ShopOutlined />,
        showInMenu: true,
        component: (props) => LazyComponent(() => import('src/scenes/InterventionChangeRequests'), props),
    },
    {
        path: '/logout',
        permission: '',
        title: 'Logout',
        name: 'logout',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/components/Logout'), props),
    },
    {
        path: '/exception',
        permission: '',
        title: 'exception',
        name: 'exception',
        icon: <Icons.InfoCircleOutlined />,
        showInMenu: false,
        component: (props) => LazyComponent(() => import('src/scenes/Exception'), props),
    },
];

export const routers = [
    ...userRouter,
    ...branchRouter,
    ...pageRouter,
    ...appRouters,
    ...managerRouters,
    ...employerRouters,
    ...supplierRouters,
];
