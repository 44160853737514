import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { GetAllQuestionOutput } from './dto/getAllQuestionOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { CreateOrUpdateQuestionInput } from './dto/createOrUpdateQuestionInput';
import { GetQuestionOutput } from './dto/getQuestionOutput';
import { CreateOrUpdateQuestionIntroInput } from './dto/createOrUpdateQuestionIntroInput';
import { GetQuestionIntroOutput } from './dto/getQuestionIntroOutput';
import { GetAllQuestionsRequest } from './dto/getAllQuestionsRequest';

class QuestionService {
  public async create(createQuestionInput: CreateOrUpdateQuestionInput) {
    const result = await http.post('api/services/app/Question/Create', createQuestionInput);
    return result.data.result;
  }

  public async createQuestionIntro(createQuestionIntroInput: CreateOrUpdateQuestionIntroInput) {
    const result = await http.post('api/services/app/Question/CreateQuestionIntro', createQuestionIntroInput);
    return result.data.result;
  }

  public async update(updateQuestionInput: CreateOrUpdateQuestionInput) {
    const result = await http.put('api/services/app/Question/Update', updateQuestionInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto<string>) {
    const result = await http.delete('api/services/app/Question/Delete', { params: entityDto });
    return result.data;
  }

  public async get(entityDto: EntityDto<string>): Promise<GetQuestionOutput> {
    const result = await http.get('api/services/app/Question/Get', { params: entityDto });
    return result.data.result;
  }

  public async getIntro(entityDto: EntityDto<string>): Promise<GetQuestionIntroOutput> {
    const result = await http.get('api/services/app/Question/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(getAllQuestionsRequest: GetAllQuestionsRequest): Promise<PagedResultDto<GetAllQuestionOutput>> {
    const result = await http.get('api/services/app/Question/GetAll', { params: getAllQuestionsRequest });
    return result.data.result;
  }
}

export default new QuestionService();
