import { action, makeObservable, observable, runInAction } from 'mobx';
import TreeDataItem from 'src/models/treedataItem';
import billingEntityService from 'src/services/billingEntities/billingEntityService';
import type { BillingEntityDto } from 'src/services/billingEntities/dto/BillingEntityDto';
import { BillingEntityTreeDataDto } from 'src/services/billingEntities/dto/billingEntityTreeDataDto';
class BillingEntityStore {
    @observable activeBillingEntitiesTreeData: TreeDataItem[];
    @observable billingEntities: BillingEntityTreeDataDto[];

    constructor() {
        makeObservable(this);
    }

    @action
    async getAll(businessUnitId?: string) {
        if (!businessUnitId) {
            this.billingEntities = [];
            this.activeBillingEntitiesTreeData = [];
            return;
        }

        const result = await billingEntityService.getAll(businessUnitId);
        runInAction(() => {
            this.billingEntities = result;
            // todo: It is possible these entities do not contain businessUnitId etc
            this.activeBillingEntitiesTreeData = this.getSelectData([
                ...result,
                { name: 'Afwijkende configuratie', id: 'null', billingEntities: [], children: [] },
            ]);
        });
    }

    @action
    async getById(billingEntityId: string) {
        const result = await billingEntityService.getById(billingEntityId);
        return result;
    }

    @action
    async getByBusinessUnitId(businessUnitId: string) {
        const result = await billingEntityService.getByBusinessUnitId(businessUnitId);
        return result;
    }

    @action
    async getEmployerNamesByBillingId(billingEntityId: string) {
        const result = await billingEntityService.getEmployerNamesByBillingId(billingEntityId);
        return result;
    }

    @action
    async update(billingEntity: BillingEntityDto) {
        const result = await billingEntityService.update(billingEntity);
        return result;
    }

    @action
    async create(billingEntity: BillingEntityDto) {
        const result = await billingEntityService.create(billingEntity);
        return result;
    }

    getSelectData = (children: BillingEntityTreeDataDto[]): TreeDataItem[] => {
        return (
            children.map((e) => ({
                title: e.name,
                value: e.id.toString(),
                selectable: e.billingEntities?.length > 0 || e.id === 'null',
                billingEntites: e.billingEntities,
                children: this.getSelectData(e.children),
            })) ?? []
        );
    };
}

export default BillingEntityStore;
