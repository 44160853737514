import { BaseFeedbackInput, OrderFeedbackDoneInput } from 'src/services/feedback/dto/FeedbackInput';
import { OrderFeedbackNotStartedInput, OrderFeedbackStartedInput } from '../feedback/dto/FeedbackInput';
import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { CreateOrUpdateOrderInput } from './dto/CreateOrUpdateOrderInput';
import { GetOrderOutput } from './dto/getOrderOutput';
import { GetAllOrdersInput } from './dto/getAllOrdersInput';
import moment from 'moment-timezone';
import { OrderApprovalInput } from './dto/orderApprovalInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { GetOrderRequestOutput } from './dto/getOrderRequestOutput';
import { OrderConfirmationDto } from './dto/orderConfirmationDto';
import { SupplierDatesInput } from './dto/supplierDatesInput';

class OrderService {
    public async create(createUpdateOrderInput: CreateOrUpdateOrderInput) {
        const result = await http.post('api/services/app/Order/Create', createUpdateOrderInput);
        return result.data.result;
    }

    public async update(createUpdateOrderInput: CreateOrUpdateOrderInput) {
        const result = await http.put('api/services/app/Order/Update', createUpdateOrderInput);
        return result.data.result;
    }

    public async approve(approvalInput: OrderApprovalInput) {
        const result = await http.post('api/services/app/Order/Approval', approvalInput);
        return result.data.result;
    }

    public async expire(input: { orderId: string }) {
        const result = await http.post('api/services/app/Order/Expiration', input);
        return result.data.result;
    }

    public async confirm(confirmationInput: OrderConfirmationDto) {
        const result = await http.post('api/services/app/Order/Confirmation', confirmationInput);
        return result.data.result;
    }

    public async cancelConfirmed(confirmationInput: OrderConfirmationDto) {
        const result = await http.post('api/services/app/Order/CancelConfirmed', confirmationInput);
        return result.data.result;
    }

    public async confirmAdditionalData(confirmationInput: OrderConfirmationDto) {
        const result = await http.post('api/services/app/Order/ConfirmAdditionalData', confirmationInput);
        return result.data.result;
    }

    public async confirmStap(confirmationInput: OrderConfirmationDto) {
        const result = await http.post('api/services/app/Order/ConfirmStap', confirmationInput);
        return result.data.result;
    }

    public async updateSupplierDates(datesInput: SupplierDatesInput) {
        const result = await http.patch('api/services/app/Order/UpdateSupplierDates', datesInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Order/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto<string>): Promise<GetOrderOutput> {
        const result = await http.get('api/services/app/Order/Get', {
            params: entityDto,
            transformResponse: this.ConvertOrder,
        });
        return result.data.result;
    }

    public async getAllOrderRequests(
        input: PagedFilterAndSortedRequest
    ): Promise<PagedResultDto<GetOrderRequestOutput>> {
        const result = await http.get('api/services/app/Order/GetAllOrderRequests', { params: input });
        return result.data.result;
    }

    public async getAll(input: GetAllOrdersInput): Promise<PagedResultDto<GetOrderOutput>> {
        const result = await http.get('api/services/app/Order/GetAll', { params: input });
        return result.data.result;
    }

    public async getExport(input: GetAllOrdersInput) {
        const result = await http.get('api/services/app/Order/GetExport', { params: input });
        return result;
    }

    public async getExportCostLines(input: GetAllOrdersInput) {
        const result = await http.get('api/services/app/Order/GetExportCostLines', { params: input });
        return result;
    }

    public async submitFeedbackNotStarted(input: OrderFeedbackNotStartedInput) {
        const result = await http.post('/api/services/app/Order/FeedbackNotStarted', input);
        return result.data.result;
    }

    public async submitFeedbackStarted(input: OrderFeedbackStartedInput) {
        const result = await http.post('/api/services/app/Order/FeedbackStarted', input);
        return result.data.result;
    }

    public async submitFeedbackDone(input: OrderFeedbackDoneInput) {
        const result = await http.post('/api/services/app/Order/FeedbackDone', input);
        return result.data.result;
    }

    public async submitOrderFeedbackOpen(input: BaseFeedbackInput) {
        const result = await http.post('api/services/app/Feedback/CreateOrderFeedbackOpen', input);
        return result.data.result;
    }

    ConvertOrder(result: any) {
        const data = JSON.parse(result);
        if (data && data.result) {
            data.result.confirmationDate = data.result.confirmationDate
                ? moment(data.result.confirmationDate)
                : undefined;
            data.result.startDate = data.result.startDate ? moment(data.result.startDate) : undefined;
            data.result.endDate = data.result.endDate ? moment(data.result.endDate) : undefined;

            if (data.result.additionalPersonalInfo) {
                data.result.additionalPersonalInfo.dateOfBirth = data.result.additionalPersonalInfo.dateOfBirth
                    ? moment(data.result.additionalPersonalInfo.dateOfBirth)
                    : undefined;
            }
        }
        return data;
    }
}

export default new OrderService();
