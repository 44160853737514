import http from '../httpService';
import { GetSurveySubmissionOutput } from './dto/getSurveySubmissionOutput';
import { EntityDto } from '../dto/entityDto';
import SurveySubmissionStatisticsOutput from './dto/statistics/SurveySubmissionStatisticsOutput';
import SurveySubmissionStatisticsRequest from './dto/statistics/SurveySubmissionStatisticsRequest';

class SurveySubmissionService {
    public async get(entityDto: EntityDto<string>): Promise<GetSurveySubmissionOutput> {
        const result = await http.get('api/services/app/SurveySubmission/Get', { params: entityDto });
        return result.data.result;
    }

    public async getByEmployee(entityDto: EntityDto<string>): Promise<GetSurveySubmissionOutput> {
        const result = await http.get('api/services/app/SurveySubmission/GetByEmployee', { params: entityDto });
        return result.data.result;
    }

    public async getStatistics(request: SurveySubmissionStatisticsRequest): Promise<SurveySubmissionStatisticsOutput> {
        const result = await http.get('api/services/app/SurveySubmission/GetStatistics', { params: request });

        return result.data.result;
    }

    public async getStatisticsExport(request: SurveySubmissionStatisticsRequest) {
        const result = await http.get('api/services/app/SurveySubmission/GetStatisticsExport', {
            params: request,
            responseType: 'arraybuffer',
        });
        return result;
    }
}

export default new SurveySubmissionService();
