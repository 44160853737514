import http from '../httpService';
import CreateApiUserInput from './dto/createApiUserInput';
import CreateApiUserOutput from './dto/createApiUserOutput';
import RevokeApiUserInput from './dto/revokeApiUserInput';

class ApiUserService {
    public async createForEmployer(input: CreateApiUserInput): Promise<CreateApiUserOutput> {
        const result = await http.post('api/services/app/ApiUser/CreateForEmployer', input);
        return result.data.result;
    }

    public async revokeForEmployer(input: RevokeApiUserInput): Promise<void> {
        const result = await http.delete('api/services/app/ApiUser/DeleteForEmployer', { params: input });
        return result.data.result;
    }

    public async createForBusinessUnit(input: CreateApiUserInput): Promise<CreateApiUserOutput> {
        const result = await http.post('api/services/app/ApiUser/CreateForBusinessUnit', input);
        return result.data.result;
    }

    public async revokeForBusinessUnit(input: RevokeApiUserInput): Promise<void> {
        const result = await http.delete('api/services/app/ApiUser/DeleteForBusinessUnit', { params: input });
        return result.data.result;
    }
}

export default new ApiUserService();
