import { GetSurveyOutput } from 'src/services/survey/dto/getSurveyOutput';
import http from '../httpService';
import { EntityDto } from '../dto/entityDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { PagedResultDto } from '../dto/pagedResultDto';
import SurveyStatus from 'src/models/Survey/SurveyStatus';
import { CreateOrUpdateSurveyInput } from './dto/createOrUpdateSurveyInput';

class SurveyService {
    public async get(entityDto: EntityDto<string>): Promise<GetSurveyOutput> {
        const result = await http.get('api/services/admin/Survey/GetSurvey', { params: entityDto });

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async getAllSurvey(
        pagedFilterAndSortedRequest: PagedFilterAndSortedRequest
    ): Promise<PagedResultDto<GetSurveyOutput>> {
        const result = await http.get('api/services/admin/Survey/GetAllSurvey', {
            params: pagedFilterAndSortedRequest,
        });

        for (let index = 0; index < result.data.result.items.length; index++) {
            this.mapSurveyStatus(result.data.result.items[index]);
        }
        return result.data.result;
    }

    public async getDuplicateSurvey(entityDto: EntityDto<string>): Promise<GetSurveyOutput> {
        const result = await http.get('api/services/admin/Survey/GetDuplicateSurvey', { params: entityDto });

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async getLatestSurvey(): Promise<GetSurveyOutput> {
        const result = await http.get('api/services/admin/Survey/GetLatestSurvey');

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async create(input: CreateOrUpdateSurveyInput): Promise<GetSurveyOutput> {
        const result = await http.post('api/services/admin/Survey/CreateSurvey', input);

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async update(input: CreateOrUpdateSurveyInput): Promise<GetSurveyOutput> {
        const result = await http.put('api/services/admin/Survey/UpdateSurvey', input);

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async activateSurvey(entityDto: EntityDto<string>): Promise<GetSurveyOutput> {
        const result = await http.post('api/services/admin/Survey/ActivateSurvey', entityDto);

        this.mapSurveyStatus(result.data.result);
        return result.data.result;
    }

    public async deleteSurvey(entityDto: EntityDto<string>) {
        await http.delete('api/services/admin/Survey/DeleteSurvey', { params: entityDto });
    }

    mapSurveyStatus(element: GetSurveyOutput) {
        if (!element.isDraft && !element.isActive) {
            element.surveyStatus = SurveyStatus.Old;
        } else if (element.isDraft && !element.isActive) {
            element.surveyStatus = SurveyStatus.IsDraft;
        } else if (!element.isDraft && element.isActive) {
            element.surveyStatus = SurveyStatus.IsActive;
        } else {
            element.surveyStatus = SurveyStatus.Unknown;
        }
    }
}

export default new SurveyService();
