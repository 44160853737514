import { action, makeObservable, observable, runInAction } from 'mobx';
import { GetCurrentLoginInformations } from 'src/services/session/dto/getCurrentLoginInformations';
import sessionService from 'src/services/session/sessionService';

class SessionStore {
    @observable currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();

    constructor() {
        makeObservable(this);
    }

    @action
    async getCurrentLoginInformations() {
        const result = await sessionService.getCurrentLoginInformations();
        runInAction(() => {
            this.currentLogin = result;
        });
    }
}

export const sessionInstance = new SessionStore();

export default SessionStore;
