import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isGranted } from 'src/lib/abpUtility';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateInterventionChangeRequestInput } from 'src/services/intervention/dto/createOrUpdateInterventionChangeRequestInput';
import type DeleteChangedInterventionChangeRequestInput from 'src/services/intervention/dto/deleteChangedInterventionChangeRequestInput';
import type DeleteInterventionChangeRequestInput from 'src/services/intervention/dto/deleteInterventionChangeRequestInput';
import { GetAllInterventionChangeRequestOutput } from 'src/services/intervention/dto/getAllInterventionChangeRequestOutput';
import type { GetAllInterventionInput } from 'src/services/intervention/dto/getAllInterventionInput';
import { GetInterventionChangeRequestOutput } from 'src/services/intervention/dto/getInterventionChangeRequestOutput';
import InterventionChangeRequestService from 'src/services/intervention/interventionChangeRequestService';
import SessionStore from './sessionStore';

type ObservableInterventionList = null | PagedResultDto<GetAllInterventionChangeRequestOutput>;
type ObservableInterventionDetail =
    | null
    | CreateOrUpdateInterventionChangeRequestInput
    | GetInterventionChangeRequestOutput;

export class InterventionChangeRequestStore {
    @observable interventionList: ObservableInterventionList;
    @observable interventionDetail: ObservableInterventionDetail;
    private _sessionStore: SessionStore;

    constructor(sessionStore: SessionStore) {
        makeObservable(this);
        this._sessionStore = sessionStore;
    }

    @computed
    get isEmployer() {
        return isGranted('Employer.Interventions.ManageOwnSupply') && !!this._sessionStore?.currentLogin?.employer;
    }

    @computed
    get isSupplier() {
        return isGranted('Supplier.Interventions.ManageOwnSupply') && !!this._sessionStore?.currentLogin?.supplier;
    }

    @computed
    get isAdmin() {
        return (
            isGranted('Shop.Interventions.ManageOwnSupplyRequests') &&
            !this._sessionStore?.currentLogin?.employer &&
            !this._sessionStore?.currentLogin?.supplier
        );
    }

    @action
    async getAll(input: GetAllInterventionInput) {
        let result: ObservableInterventionList = null;
        if (this.isSupplier) {
            result = await InterventionChangeRequestService.suppliersGetAll(input);
        } else if (this.isEmployer) {
            result = await InterventionChangeRequestService.getAll(input);
        } else if (this.isAdmin) {
            result = await InterventionChangeRequestService.adminGetAll(input);
        }

        runInAction(() => {
            this.interventionList = result;
        });
    }

    @action
    async get(id: string) {
        let result: ObservableInterventionDetail = null;
        if (this.isSupplier) {
            result = await InterventionChangeRequestService.suppliersGet(id);
        } else if (this.isEmployer) {
            result = await InterventionChangeRequestService.get(id);
        } else if (this.isAdmin) {
            // non use-case
        }

        runInAction(() => {
            this.interventionDetail = result;
        });
    }

    @action
    async getChanged(id: string) {
        let result: ObservableInterventionDetail = null;
        if (this.isSupplier) {
            result = await InterventionChangeRequestService.suppliersGetChanged(id);
        } else if (this.isEmployer) {
            result = await InterventionChangeRequestService.getChanged(id);
        } else if (this.isAdmin) {
            result = await InterventionChangeRequestService.adminGetChanged(id);
        }

        runInAction(() => {
            this.interventionDetail = result;
        });
    }

    @action
    async new() {
        this.interventionDetail = {
            supplierId: '',
            showUrlInApp: false,
            vatPercentage: 21,
            id: undefined,
            interventionId: undefined,
            type: undefined,
            themes: [],
            themeIds: undefined,
            externalOrderUrl: undefined,
            externalOrderEmailAddress: undefined,
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    summary: '',
                    description: '',
                    url: '',
                    location: '',
                    durationAmount: 0,
                    durationUnit: '',
                },
            ],
            requestedImplementationDate: undefined,
            costs: 0,
            isChangeRequest: true,
            kickbackCostExclusiveVat: 0,
            sendStatusToEmail: this._sessionStore.currentLogin.user.emailAddress,
        };
    }

    @action
    async create(input: CreateOrUpdateInterventionChangeRequestInput) {
        let result: any = null;
        if (this.isSupplier) {
            result = await InterventionChangeRequestService.suppliersCreate(input);
        } else if (this.isEmployer) {
            result = await InterventionChangeRequestService.create(input);
        } else if (this.isAdmin) {
            // non use-case
        }

        runInAction(() => {
            this.interventionList?.items.push(result);
        });
    }

    @action
    async update(input: CreateOrUpdateInterventionChangeRequestInput) {
        let result: GetAllInterventionChangeRequestOutput | null = null;
        if (this.isSupplier) {
            result = await InterventionChangeRequestService.suppliersCreate(input);
        } else if (this.isEmployer) {
            result = await InterventionChangeRequestService.create(input);
        } else if (this.isAdmin) {
            result = await InterventionChangeRequestService.adminUpdate(input);
        }

        runInAction(() => {
            if (this.interventionList) {
                this.interventionList.items = this.interventionList.items.map((x) => {
                    if (result && x.id === input.id) x = result;
                    return x;
                });
            }
        });
    }

    @action
    async delete(input: DeleteInterventionChangeRequestInput) {
        if (this.isSupplier) {
            await InterventionChangeRequestService.suppliersDelete(input);
        } else if (this.isEmployer) {
            await InterventionChangeRequestService.delete(input);
        } else if (this.isAdmin) {
            // non use-case
        }

        runInAction(() => {
            if (this.interventionList) {
                this.interventionList.items = this.interventionList.items.filter((x) => x.id !== input.interventionId);
                this.interventionDetail = null;
            }
        });
    }

    @action
    async deleteChanged(input: DeleteChangedInterventionChangeRequestInput) {
        if (this.isSupplier) {
            await InterventionChangeRequestService.suppliersDeleteChanged(input);
        } else if (this.isEmployer) {
            await InterventionChangeRequestService.deleteChanged(input);
        } else if (this.isAdmin) {
            // non use-case
        }

        runInAction(() => {
            if (this.interventionList) {
                this.interventionList.items = this.interventionList.items.filter((x) => x.id !== input.id);
                this.interventionDetail = null;
            }
        });
    }
}
