import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { EntityDto } from 'src/services/dto/entityDto';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import http from '../httpService';
import { GetAllEmployeesInput } from './dto/GetAllEmployeesInput';
import { GetAllEmployerAccountsInput } from './dto/GetAllEmployerAccountsInput';
import { GetAllEmployersInput } from './dto/GetAllEmployersInput';
import { SendActivationMailInput } from './dto/SendActivationMailInput';
import { UpdateTotalBudgetDto } from './dto/UpdateTotalBudgetDto';
import { CreateOrUpdateEmployeeInput, CreateOrUpdateEmployeeInputCollectionDto } from './dto/createOrUpdateEmployeeInput';
import { CreateOrUpdateEmployerAccountInput } from './dto/createOrUpdateEmployerAccountInput';
import { CreateOrUpdateEmployerInput, DuplicateEmployerInput } from './dto/createOrUpdateEmployerInput';
import { EmployeeActivationTokenDto } from './dto/employeeActivationTokenDto';
import { EmployeeImportDeleteDto } from './dto/employeeImportDeleteDto';
import { EmployeeImportDeleteDtoCollection } from './dto/employeeImportDeleteDto';
import EmployerStatisticsInput from './dto/employerStatisticsInput';
import { GetEmployeeOutput } from './dto/getEmployeeOutput';
import { GetEmployerAccountOutput } from './dto/getEmployerAccountOutput';
import { GetEmployerOutput } from './dto/getEmployerOutput';
import { EmployeeImportItem } from '@app/scenes/Employees/components/employeeImportItem';
import { ProgressResultDto } from './dto/ProgressResult';

class UserProfilesService {
    public async createEmployee(
        createUserInput: CreateOrUpdateEmployeeInput,
        config?: AxiosRequestConfig
    ): Promise<GetEmployeeOutput> {
        const result = await http.post('api/services/app/Employee/Create', createUserInput, config);
        return result.data.result;
    }

    public async createEmployees(
        createUserInput?: CreateOrUpdateEmployeeInputCollectionDto,
        config?: AxiosRequestConfig
    ): Promise<GetEmployeeOutput[]> {
        const result = await http.post('api/services/app/Employee/CreateMany', createUserInput, config);
        return result.data.result;
    }

    public async deleteEmployees(
        deleteUserInput?: EmployeeImportDeleteDtoCollection,
        config?: AxiosRequestConfig
    ): Promise<EmployeeImportDeleteDto[]> {
        const result = await http.post('api/services/app/Employee/DeleteManyFromImport', deleteUserInput, config);
        return result.data.result;
    }

    public async getProgress(
        id?: number,
    ): Promise<ProgressResultDto> {
        const result = await http.get('api/services/app/Employee/GetImportProgress?requestId=' + id);
        return result.data.result;
    }

    public async completeProgress(
        id?: number,
    ) {
        const result = await http.post('api/services/app/Employee/CompleteProgress?requestId=' + id);
        return result.data.result;
    }

    public async startProgress(
        id?: number,
        totalCount?: number
    ) {
        const result = await http.post('api/services/app/Employee/StartImportProgress', { ProgressId: id, TotalCount: totalCount });
        return result.data.result;
    }

    public async getSampleExcel(employerId?: string) {
        return await http.get('api/services/app/Employee/GenerateSampleExcel?employerId=' + employerId, {
            responseType: 'arraybuffer',
        });
    }

    public async createEmployer(createUserInput: CreateOrUpdateEmployerInput): Promise<GetEmployerOutput> {
        const result = await http.post('api/services/app/Employer/Create', createUserInput);
        return result.data.result;
    }

    public async duplicateEmployer(duplicateUserInput: DuplicateEmployerInput): Promise<GetEmployerOutput> {
        const result = await http.post('api/services/app/Employer/Duplicate', duplicateUserInput);
        return result.data.result;
    }

    public async createEmployerAccount(
        createUserInput: CreateOrUpdateEmployerAccountInput
    ): Promise<GetEmployerAccountOutput> {
        const result = await http.post('api/services/app/EmployerAccount/Create', createUserInput);
        return result.data.result;
    }

    public async updateEmployee(updateUserInput: CreateOrUpdateEmployeeInput): Promise<GetEmployeeOutput> {
        const result = await http.put('api/services/app/Employee/Update', updateUserInput);
        return result.data.result;
    }

    public async updateEmployer(updateUserInput: CreateOrUpdateEmployerInput): Promise<GetEmployerOutput> {
        const result = await http.put('api/services/app/Employer/Update', updateUserInput);
        return result.data.result;
    }

    public async updateEmployerAccount(
        updateUserInput: CreateOrUpdateEmployerAccountInput
    ): Promise<GetEmployerAccountOutput> {
        const result = await http.put('api/services/app/EmployerAccount/Update', updateUserInput);
        return result.data.result;
    }

    public async deleteEmployee(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Employee/Delete', { params: entityDto });
        return result.data;
    }

    public async deleteEmployeeFromImport(input: EmployeeImportDeleteDto, config?: Omit<AxiosRequestConfig, 'params'>) {
        const result = await http.delete('api/services/app/Employee/DeleteFromImport', {
            ...config,
            params: input,
        });
        return result.data;
    }

    public async deleteEmployer(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Employer/Delete', { params: entityDto });
        return result.data;
    }

    public async deleteEmployerAccount(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/EmployerAccount/Delete', { params: entityDto });
        return result.data;
    }

    public async getEmployee(entityDto: EntityDto<string>): Promise<GetEmployeeOutput> {
        const result = await http.get('api/services/app/Employee/Get', {
            params: entityDto,
            transformResponse: this.convertEmployee,
        });
        return result.data.result;
    }

    public async getEmployer(entityDto: EntityDto<string>): Promise<GetEmployerOutput> {
        const result = await http.get('api/services/app/Employer/Get', { params: entityDto });
        return result.data.result;
    }

    public async getEmployerAccount(entityDto: EntityDto<string>): Promise<GetEmployerAccountOutput> {
        const result = await http.get('api/services/app/EmployerAccount/Get', { params: entityDto });
        return result.data.result;
    }

    public async getAllEmployees(
        pagedFilterAndSortedRequest: GetAllEmployeesInput
    ): Promise<PagedResultDto<GetEmployeeOutput>> {
        const result = await http.get('api/services/app/Employee/GetAll', {
            params: pagedFilterAndSortedRequest,
            transformResponse: this.convertEmployees,
        });
        return result.data.result;
    }

    public async getAllEmployers(
        pagedFilterAndSortedRequest: GetAllEmployersInput
    ): Promise<PagedResultDto<GetEmployerOutput>> {
        const result = await http.get('api/services/app/Employer/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }

    public async getAllEmployerAccounts(
        pagedFilterAndSortedRequest: GetAllEmployerAccountsInput
    ): Promise<PagedResultDto<GetEmployerAccountOutput>> {
        const result = await http.get('api/services/app/EmployerAccount/GetAll', {
            params: pagedFilterAndSortedRequest,
        });
        return result.data.result;
    }

    public async sendEmployeeActivationMail(input: SendActivationMailInput) {
        const result = await http.post('api/services/app/Employee/SendActivationMail', input);
        return result.data;
    }

    public async sendEmployerActivationMail(entityDto: EntityDto<string>) {
        const result = await http.post('api/services/app/Employer/SendActivationMail', entityDto);
        return result.data;
    }

    public async sendEmployerAccountActivationMail(entityDto: EntityDto<string>) {
        const result = await http.post('api/services/app/EmployerAccount/SendActivationMail', entityDto);
        return result.data;
    }

    public async setTotalBudget(input: UpdateTotalBudgetDto) {
        const result = await http.post('api/services/app/Employer/SetTotalBudget', input);
        return result.data;
    }

    public async getActivationCode(input: EntityDto<string>): Promise<EmployeeActivationTokenDto> {
        const result = await http.get('api/services/app/Employee/GetActivationCode', { params: input });
        return result.data.result as EmployeeActivationTokenDto;
    }

    public async exportActivationCodes() {
        return await http.get('api/services/app/Employee/GetActivationCodes', {
            responseType: 'arraybuffer',
        });
    }

    public async getStatistics(input: EmployerStatisticsInput) {
        const result = await http.get('api/services/app/Employer/GetStatistics', { params: input });
        return result.data.result;
    }

    public async ParseMutationsExcel(employerId: string, file: any): Promise<EmployeeImportItem[]> {
        let formData = new FormData();
        formData.append('ExcelFile', file);
        formData.append( 'employerId', employerId );
        const result = await http.post('api/services/app/Employee/ParseMutationsExcel', formData);
        return result.data.result;
    }
    public async lockEmployee(entityDto: EntityDto<string>) {
        const result = await http.post('api/services/app/Employee/LockAccount', entityDto);
        return result.data;
    }

    convertEmployees(response: any) {
        const data = JSON.parse(response);

        if (data && data.result && data.result.items) {
            for (let i = 0; i < data.result.items.length; i++) {
                let employee: GetEmployeeOutput = data.result.items[i];

                employee.scheduledCreationDate = employee.scheduledCreationDate
                    ? moment(employee.scheduledCreationDate)
                    : undefined;

                employee.scheduledDeletionDate = employee.scheduledDeletionDate
                    ? moment(employee.scheduledDeletionDate)
                    : undefined;

                employee.contractDate = employee.contractDate 
                    ? moment(employee.contractDate) 
                    : undefined
            }
        }

        return data;
    }

    convertEmployee(result: any) {
        const data = JSON.parse(result);
        if (data && data.result) {
            data.result.scheduledCreationDate = data.result.scheduledCreationDate
                ? moment(data.result.scheduledCreationDate)
                : undefined;

            data.result.scheduledDeletionDate = data.result.scheduledDeletionDate
                ? moment(data.result.scheduledDeletionDate)
                : undefined;

                
            data.result.contractDate = data.result.contractDate 
                ? moment(data.result.contractDate) 
                : undefined
        }
        return data;
    }
}

export default new UserProfilesService();
