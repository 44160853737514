import http from '../httpService';
import StatisticsInput from './dto/statisticsInput';
import PopularInterventionStatisticsInput from './dto/popularInterventionStatisticsInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { CancelToken } from 'axios';

class DashboardService {
    async getGeneralStatistics(input: StatisticsInput, cancelToken: CancelToken) {
        try {
            const result = await http.get('/api/services/app/Dashboard/GetGeneralStatistics', {
                params: input,
                cancelToken: cancelToken,
            });
            return result.data.result;
        } catch (e) {
            // Ignore cancellation
        }
    }

    async getActivationStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetActivationStatistics', { params: input });
        return result.data.result;
    }

    async getActivationRankingStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetActivationRankingStatistics', { params: input });
        return result.data.result;
    }

    async getInterventionStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetInterventionStatistics', { params: input });
        return result.data.result;
    }

    async getPopularInterventionStatistics(input: PopularInterventionStatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetPopularInterventionStatistics', {
            params: input,
        });
        return result.data.result;
    }

    async getRevenueStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetRevenueStatistics', { params: input });
        return result.data.result;
    }

    async getRevenueRankingStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetRevenueRankingStatistics', { params: input });
        return result.data.result;
    }

    async getBudgetStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetBudgetStatistics', { params: input });
        return result.data.result;
    }

    async getOrderRankingStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetOrderRankingStatistics', { params: input });
        return result.data.result;
    }

    async getNotificationStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetNotificationStatistics', { params: input });
        return result.data.result;
    }

    async getFeedbackStatistics(input: StatisticsInput) {
        const result = await http.get('/api/services/app/Dashboard/GetFeedbackStatistics', { params: input });
        return result.data.result;
    }

    async getAllFeedback(input: StatisticsInput, request: PagedFilterAndSortedRequest) {
        const result = await http.get('/api/services/app/Dashboard/GetAllFeedback', {
            params: { ...input, ...request },
        });
        return result.data.result;
    }
}

export default new DashboardService();
