import http from '../httpService';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityDto } from '../dto/entityDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { GetArticleOutput } from './dto/getArticleOutput';

class ArticleService {
  public async create(createArticleInput: GetArticleOutput) {
    const result = await http.post('api/services/app/Article/Create', createArticleInput);
    return result.data.result;
  }

  public async update(updateArticleInput: GetArticleOutput) {
    const result = await http.put('api/services/app/Article/Update', updateArticleInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto<string>) {
    const result = await http.delete('api/services/app/Article/Delete', { params: entityDto });
    return result.data;
  }

  public async get(entityDto: EntityDto<string>): Promise<GetArticleOutput> {
    const result = await http.get('api/services/app/Article/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<GetArticleOutput>> {
    const result = await http.get('api/services/app/Article/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  public async getPublished(): Promise<GetArticleOutput[]> {
    const result = await http.get('api/services/app/Article/GetPublished');
    return result.data.result;
  }
}

export default new ArticleService();
