import dayjs from 'dayjs';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import http from '../httpService';
import { CreateOrUpdateInterventionChangeRequestInput } from './dto/createOrUpdateInterventionChangeRequestInput';
import DeleteChangedInterventionChangeRequestInput from './dto/deleteChangedInterventionChangeRequestInput';
import DeleteInterventionChangeRequestInput from './dto/deleteInterventionChangeRequestInput';
import { GetAllInterventionChangeRequestOutput } from './dto/getAllInterventionChangeRequestOutput';
import { GetAllInterventionInput } from './dto/getAllInterventionInput';
import { GetInterventionChangeRequestOutput } from './dto/getInterventionChangeRequestOutput';

class InterventionChangeRequestService {
    groupBaseUrl = 'api/services/admin/ChangeRequestIntervention';

    // #region Get all
    public async getAll(
        input: GetAllInterventionInput
    ): Promise<PagedResultDto<GetAllInterventionChangeRequestOutput>> {
        const result = await http.get(`${this.groupBaseUrl}/GetAllInterventions`, {
            params: input,
        });
        return result.data.result;
    }

    public async suppliersGetAll(
        input: GetAllInterventionInput
    ): Promise<PagedResultDto<GetAllInterventionChangeRequestOutput>> {
        const result = await http.get(`${this.groupBaseUrl}/Suppliers/GetAllInterventions`, {
            params: input,
        });
        return result.data.result;
    }

    public async adminGetAll(
        input: GetAllInterventionInput
    ): Promise<PagedResultDto<GetAllInterventionChangeRequestOutput>> {
        const result = await http.get(`${this.groupBaseUrl}/GetAll`, {
            params: input,
        });
        return result.data.result;
    }
    // #endregion

    // #region Get intervention (isChangeRequest = false)
    public async get(id: string): Promise<GetInterventionChangeRequestOutput> {
        const result = await http.get(`${this.groupBaseUrl}/GetIntervention/${id}`, {
            transformResponse: this.convertDate,
        });
        return result.data.result;
    }
    public async suppliersGet(id: string): Promise<GetInterventionChangeRequestOutput> {
        const result = await http.get(`${this.groupBaseUrl}/Suppliers/GetIntervention/${id}`, {
            transformResponse: this.convertDate,
        });
        return result.data.result;
    }
    // #endregion

    // #region Get change request (isChangeRequest = true)
    public async getChanged(id: string): Promise<GetInterventionChangeRequestOutput> {
        const result = await http.get(`${this.groupBaseUrl}/GetChangedIntervention/${id}`, {
            transformResponse: this.convertDate,
        });
        return result.data.result;
    }

    public async suppliersGetChanged(id: string): Promise<GetInterventionChangeRequestOutput> {
        const result = await http.get(`${this.groupBaseUrl}/Suppliers/GetChangedIntervention/${id}`, {
            transformResponse: this.convertDate,
        });
        return result.data.result;
    }

    public async adminGetChanged(id: string): Promise<GetInterventionChangeRequestOutput> {
        const result = await http.get(`${this.groupBaseUrl}/${id}`, {
            transformResponse: this.convertDate,
        });
        return result.data.result;
    }
    // #endregion

    // #region Create & update
    public async create(input: CreateOrUpdateInterventionChangeRequestInput) {
        const result = await http.post(`${this.groupBaseUrl}/Create`, input);
        return result.data.result;
    }

    public async suppliersCreate(input: CreateOrUpdateInterventionChangeRequestInput) {
        const result = await http.post(`${this.groupBaseUrl}/Suppliers/CreateChangeRequest`, input);
        return result.data.result;
    }

    public async adminUpdate(input: CreateOrUpdateInterventionChangeRequestInput) {
        const result = await http.put(`${this.groupBaseUrl}/UpdateApproval`, input);
        return result.data.result;
    }
    // #endregion

    // #region Delete intervention (isChangeRequest = false)
    public async delete(input: DeleteInterventionChangeRequestInput) {
        const result = await http.put(`${this.groupBaseUrl}/DeleteInterventionRequest`, input);
        return result.data;
    }

    public async suppliersDelete(input: DeleteInterventionChangeRequestInput) {
        const result = await http.put(`${this.groupBaseUrl}/Suppliers/DeleteInterventionRequest`, input);
        return result.data;
    }
    // #endregion

    // #region Delete change request (isChangeRequest = true)
    public async deleteChanged(input: DeleteChangedInterventionChangeRequestInput) {
        const result = await http.delete(`${this.groupBaseUrl}/DeleteChangeRequest`, {
            params: input,
        });
        return result.data;
    }

    public async suppliersDeleteChanged(input: DeleteChangedInterventionChangeRequestInput) {
        const result = await http.delete(`${this.groupBaseUrl}/Suppliers/DeleteChangeRequest`, {
            params: input,
        });
        return result.data;
    }
    // #endregion

    private convertDate(result: any) {
        const data = JSON.parse(result);
        if (data && data.result) {
            data.result.requestedImplementationDate = data.result.requestedImplementationDate
                ? dayjs(data.result.requestedImplementationDate)
                : undefined;

            data.result.requestedDeletionDate = data.result.requestedDeletionDate
                ? dayjs(data.result.requestedDeletionDate)
                : undefined;
        }
        return data;
    }
}

export default new InterventionChangeRequestService();
