import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { CreateOrUpdateInterventionInput } from './dto/createOrUpdateInterventionInput';
import { GetInterventionOutput } from './dto/getInterventionOutput';
import { GetAllInterventionOutput } from './dto/getAllInterventionOutput';
import { GetAllPublicInterventionOutput } from './dto/getAllPublicInterventionOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import moment from 'moment-timezone';
import { UpdateInterventionCategorizationInput } from './dto/updateInterventionCategorizationInput';
import { UpdateInterventionQuestionMatchInput } from './dto/UpdateInterventionQuestionMatch';
import { GetAllInterventionInput, GetAllMangeSupplyInput } from './dto/getAllInterventionInput';
import InterventionStatisticsInput from './dto/interventionStatisticsInput';
import { GetInterventionEmployerSettingsOutput } from './dto/getInterventionEmployerSettingsOutput';
import { UpdateInterventionEmployerSettingsInput } from './dto/updateInterventionEmployerSettingsInput';

class InterventionService {
    public async create(createInterventionInput: CreateOrUpdateInterventionInput) {
        const result = await http.post('api/services/admin/Intervention/Create', createInterventionInput);
        return result.data.result;
    }

    public async update(updateInterventionInput: CreateOrUpdateInterventionInput) {
        const result = await http.put('api/services/admin/Intervention/Update', updateInterventionInput);
        return result.data.result;
    }

    public async updateCategorizations(updateInterventionCategorizations: UpdateInterventionCategorizationInput) {
        const result = await http.put(
            'api/services/admin/Intervention/UpdateCategorizations',
            updateInterventionCategorizations
        );
        return result.data.result;
    }

    public async delete(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/admin/Intervention/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto<string>): Promise<GetInterventionOutput> {
        const result = await http.get('api/services/admin/Intervention/Get', {
            params: entityDto,
            transformResponse: this.ConvertIntervention,
        });
        return result.data.result;
    }

    public async getAll(
        pagedFilterAndSortedRequest: GetAllInterventionInput
    ): Promise<PagedResultDto<GetAllInterventionOutput>> {
        const result = await http.get('api/services/admin/Intervention/GetAll', {
            params: pagedFilterAndSortedRequest,
        });
        return result.data.result;
    }

    public async getAllPublic(
        pagedFilterAndSortedRequest: GetAllInterventionInput
    ): Promise<PagedResultDto<GetAllPublicInterventionOutput>> {
        const result = await http.get('api/services/app/Intervention/GetAllPublic', {
            params: pagedFilterAndSortedRequest,
        });
        return result.data.result;
    }

    public async updateIntervetionQuestionMatch(
        updateInterventionQuestionMatchInput: UpdateInterventionQuestionMatchInput
    ) {
        const result = await http.put(
            '/api/services/admin/Intervention/UpdateInterventionQuestionMatch',
            updateInterventionQuestionMatchInput
        );
        return result.data.result;
    }

    ConvertIntervention(result: any) {
        const data = JSON.parse(result);
        data.result.startDateDetermination = moment(data.result.startDateDetermination);
        return data;
    }

    public async getStatistics(input: InterventionStatisticsInput) {
        const result = await http.get('api/services/app/Intervention/GetStatistics', { params: input });

        return result.data.result;
    }

    //#region supplierAdmin
    public async getInterventionEmployerSettings(
        supplierId: string,
        employerId: string,
        pagedFilterAndSortedRequest: GetAllMangeSupplyInput
    ): Promise<PagedResultDto<GetInterventionEmployerSettingsOutput>> {
        const result = await http.get(
            `/api/services/admin/Intervention/GetAllForSupplier/${supplierId}/${employerId}`,
            {
                params: pagedFilterAndSortedRequest,
            }
        );
        return result.data.result;
    }

    public async updateInterventionEmployerSettings(
        employerId: string,
        input: UpdateInterventionEmployerSettingsInput[]
    ): Promise<any> {
        const result = await http.put(`/api/services/admin/Intervention/UpdateVisibility/${employerId}`, input);
        return result.data.result;
    }

    //#endregion
}

export default new InterventionService();
