import http from '../httpService';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityDto } from '../dto/entityDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { GetCarouselOutput } from './dto/getCarouselOutput';

class CarouselService {
    public async create(createCarouselInput: GetCarouselOutput) {
        const result = await http.post('api/services/app/Carousel/Create', createCarouselInput);
        return result.data.result;
    }

    public async update(updateCarouselInput: GetCarouselOutput) {
        const result = await http.put('api/services/app/Carousel/Update', updateCarouselInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Carousel/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto<string>): Promise<GetCarouselOutput> {
        const result = await http.get('api/services/app/Carousel/Get', { params: entityDto });
        return result.data.result;
    }

    public async getAll(
        pagedFilterAndSortedRequest: PagedFilterAndSortedRequest
    ): Promise<PagedResultDto<GetCarouselOutput>> {
        const result = await http.get('api/services/app/Carousel/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }
}

export default new CarouselService();
