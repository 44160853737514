import { ThemeConfig } from 'antd';

// See https://ant.design/docs/react/customize-theme#design-token

// Refer to _color.less
const COLORS = {
    // theme
    primary: '#0050f0',
    textBase: '#333',
    link: '#0050f0',
    error: '#ec1616',
    warning: '#fbe0d0',
    success: '#009900',
    info: '#0050f0',
    // custom
    transparent: 'transparent',
    white: '#fff',
    successLight: '#439d43',
} as const;

export const themeConfig: ThemeConfig = {
    token: {
        // SeedToken
        colorPrimary: COLORS.primary,
        colorTextBase: COLORS.textBase,
        colorLink: COLORS.link,
        colorError: COLORS.error,
        colorWarning: COLORS.warning,
        colorSuccess: COLORS.success,
        colorInfo: COLORS.info,
        fontFamily: "'Plastic Sans', Arial, Helvetica, sans-serif",
        // MapToken
        // ...
        // AliasToken
        // ...
    },
    components: {
        Button: {
            borderRadius: 100,
            fontWeight: 500,
            defaultBg: COLORS.white,
            defaultColor: COLORS.primary,
            defaultBorderColor: COLORS.primary,
        },
        Layout: {
            siderBg: COLORS.white,
            headerBg: COLORS.transparent,
            headerPadding: 0,
        },
        Switch: {
            colorPrimary: COLORS.success,
            colorPrimaryHover: COLORS.successLight,
        },
    },
};
