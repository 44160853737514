import { CreateOrUpdateSupplierInput } from './dto/createOrUpdateSupplierInput';
import http from '../httpService';
import { GetSupplierOutput } from './dto/getSupplierOutput';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityDto } from '../dto/entityDto';
import { GetAllSuppliersInput } from './dto/GetAllSuppliersInput';
import { GetPublicSupplierOutput } from './dto/getPublicSupplierOutput';
import { CreateForEmployerInput, CreateForEmployerOutput } from './dto/CreateForEmployer';
import { GetSupplierEmployerSettingsOutput } from './dto/getSupplierEmployerSettingsOutput';
import { UpdateSupplierEmployerSettingsInput } from './dto/updateSupplierEmployerSettingsInput';

class SupplierService {
    public async create(createSupplierInput: CreateOrUpdateSupplierInput) {
        const result = await http.post('api/services/app/Supplier/Create', createSupplierInput);
        return result.data.result;
    }

    public async update(updateSupplierInput: CreateOrUpdateSupplierInput) {
        const result = await http.put('api/services/app/Supplier/Update', updateSupplierInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Supplier/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto<string>): Promise<GetSupplierOutput> {
        const result = await http.get('api/services/app/Supplier/Get', { params: entityDto });
        return result.data.result;
    }

    public async getAll(pagedFilterAndSortedRequest: GetAllSuppliersInput): Promise<PagedResultDto<GetSupplierOutput>> {
        const result = await http.get('api/services/app/Supplier/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }

    public async getAllPublic(
        pagedFilterAndSortedRequest: GetAllSuppliersInput
    ): Promise<PagedResultDto<GetPublicSupplierOutput>> {
        const result = await http.get('api/services/app/Supplier/GetAllPublic', {
            params: pagedFilterAndSortedRequest,
        });
        return result.data.result;
    }

    public async getAllEmployerSuppliers(): Promise<PagedResultDto<GetSupplierOutput>> {
        const result = await http.get('api/services/app/Supplier/GetAllEmployerSuppliers');
        return result.data.result;
    }

    public async createForEmployer(input: CreateForEmployerInput): Promise<CreateForEmployerOutput> {
        const result = await http.post('api/services/app/Supplier/CreateForEmployer', input);
        return result.data.result;
    }

    //#region supplierAdmin
    public async getEmployerSupplierSettings(
        employerId: string,
        pagedFilterAndSortedRequest: GetAllSuppliersInput
    ): Promise<PagedResultDto<GetSupplierEmployerSettingsOutput>> {
        const result = await http.get(`/api/services/admin/ManageSupply/${employerId}`, {
            params: pagedFilterAndSortedRequest,
        });
        return result.data.result;
    }

    public async updateSupplierEmployerSettings(
        employerId: string,
        input: UpdateSupplierEmployerSettingsInput[]
    ): Promise<any> {
        const result = await http.put(`/api/services/admin/ManageSupply/${employerId}`, input);
        return result.data.result;
    }
    //#endregion
}

export default new SupplierService();
