import http from '../httpService';
import { BillingEntityDto } from './dto/BillingEntityDto';
import { BillingEntityTreeDataDto } from './dto/billingEntityTreeDataDto';

class BillingEntityService {
    public async getAll(businessUnitId: string): Promise<BillingEntityTreeDataDto[]> {
        const result = await http.get(`/api/services/app/BillingEntities/GetAll?businessUnitId=${businessUnitId}`);
        return result.data.result;
    }

    public async getById(billingEntityId: string): Promise<BillingEntityTreeDataDto[]> {
        const result = await http.get(`/api/services/app/BillingEntities/Get?id=${billingEntityId}`);
        return result.data.result;
    }

    public async getByBusinessUnitId(businessUnitId: string): Promise<BillingEntityDto> {
        const result = await http.get(`/api/services/app/BillingEntities/GetByBusinessUnitId?id=${businessUnitId}`);
        return result.data.result;
    }

    public async update(billingEntity: BillingEntityDto): Promise<BillingEntityDto> {
        const result = await http.put(`/api/services/app/BillingEntities/Put`, billingEntity);
        return result.data.result;
    }

    public async create(billingEntity: BillingEntityDto): Promise<BillingEntityDto> {
        const result = await http.post(`/api/services/app/BillingEntities/Post`, billingEntity);
        return result.data.result;
    }

    public async getEmployerNamesByBillingId(billingEntityId: string): Promise<string[]> {
        const result = await http.get(`/api/services/app/BillingEntities/GetEmployerNamesByBillingEntity?id=${billingEntityId}`);
        return result.data.result;
    }
}

export default new BillingEntityService();
