import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { CreateOrUpdateFeaturedInterventionDto } from './dto/CreateOrUpdateFeaturedInterventionDto​';

class FeaturedInterventionService {
  public async create(createInterventionInput: CreateOrUpdateFeaturedInterventionDto) {
    const result = await http.post('api/services/app/FeaturedIntervention/Create', createInterventionInput);
    return result.data.result;
  }

  public async update(updateInterventionInput: CreateOrUpdateFeaturedInterventionDto) {
    const result = await http.put('api/services/app/FeaturedIntervention/Update', updateInterventionInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto<string>) {
    const result = await http.delete('api/services/app/FeaturedIntervention/Delete', { params: entityDto });
    return result.data;
  }
    
  public async get(entityDto: EntityDto<string>): Promise<CreateOrUpdateFeaturedInterventionDto> {
    const result = await http.get('api/services/app/FeaturedIntervention/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<CreateOrUpdateFeaturedInterventionDto>>{
    const result = await http.get('/api/services/app/FeaturedIntervention/GetAll', {params: pagedFilterAndSortedRequest});
    return result.data.result;
  }
}

export default new FeaturedInterventionService();
