import { EntityDto } from 'src/services/dto/entityDto';
import http from '../httpService';
import { GetInterventionOutput } from './dto/getInterventionOutput';
import { GetAllInterventionOutput } from './dto/getAllInterventionOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import moment from 'moment-timezone';
import { PushExternalInterventionInput } from './dto/pushExternalInterventionInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';
import { CreateOrUpdateInterventionInput } from './dto/createOrUpdateInterventionInput';

class ExternalInterventionService {
  public async get(entityDto: EntityDto<string>): Promise<GetInterventionOutput> {
    const result = await http.get('api/services/app/ExternalIntervention/Get', { params: entityDto, transformResponse: this.ConvertIntervention });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest): Promise<PagedResultDto<GetAllInterventionOutput>> {
    const result = await http.get('api/services/app/ExternalIntervention/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  public async update(updateInterventionInput: CreateOrUpdateInterventionInput) {
    const result = await http.put('api/services/app/ExternalIntervention/Update', updateInterventionInput);
    return result.data.result;
  }

  public async pushUpdate(pushUpdateInput: PushExternalInterventionInput): Promise<any> {
    const result = await http.post('api/services/app/ExternalIntervention/PushUpdate', pushUpdateInput);
    return result.data.result;
  }

  public async pushListUpdate(pushUpdateInputs: string[]): Promise<any> {
    const result = await http.post('api/services/app/ExternalIntervention/PushListUpdate', pushUpdateInputs);
    return result.data.result;
  }

  ConvertIntervention(result: any) {
    const data = JSON.parse(result);
    data.result.startDateDetermination = moment(data.result.startDateDetermination);
    return data;
  }
}

export default new ExternalInterventionService();
