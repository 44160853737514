import { action, makeObservable, observable, runInAction } from 'mobx';
import { PaymentCondition } from 'src/models/Billing/PaymentCondition';
import { BudgetType } from 'src/models/Interventions/BudgetType';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateForEmployerInput } from 'src/services/supplier/dto/CreateForEmployer';
import type { GetAllSuppliersInput } from 'src/services/supplier/dto/GetAllSuppliersInput';
import { SupplierBillingEntityDto } from 'src/services/supplier/dto/SupplierBillingEntityDto';
import type { CreateOrUpdateSupplierInput } from 'src/services/supplier/dto/createOrUpdateSupplierInput';
import { GetPublicSupplierOutput } from 'src/services/supplier/dto/getPublicSupplierOutput';
import { GetSupplierEmployerSettingsOutput } from 'src/services/supplier/dto/getSupplierEmployerSettingsOutput';
import { GetSupplierOutput } from 'src/services/supplier/dto/getSupplierOutput';
import { UpdateSupplierEmployerSettingsInput } from 'src/services/supplier/dto/updateSupplierEmployerSettingsInput';
import SupplierService from 'src/services/supplier/supplierService';

class SupplierStore {
    @observable suppliers: PagedResultDto<GetSupplierOutput>;
    @observable publicSuppliers: PagedResultDto<GetPublicSupplierOutput>;
    @observable editSupplier: CreateOrUpdateSupplierInput;
    @observable employerSuppliers: PagedResultDto<GetSupplierOutput>;
    @observable employerSupplierSettings: PagedResultDto<GetSupplierEmployerSettingsOutput>;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createSupplierInput: CreateOrUpdateSupplierInput) {
        const result = await SupplierService.create(createSupplierInput);
        runInAction(() => {
            this.suppliers.items.push(result);
        });
    }

    @action
    async update(updateSupplierInput: CreateOrUpdateSupplierInput) {
        const result = await SupplierService.update(updateSupplierInput);
        runInAction(() => {
            this.suppliers.items = this.suppliers.items.map((x: GetSupplierOutput) => {
                if (x.id === updateSupplierInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await SupplierService.delete(entityDto);
        runInAction(() => {
            this.suppliers.items = this.suppliers.items.filter((x: GetSupplierOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await SupplierService.get(entityDto);

        runInAction(() => {
            if (result?.billingEntities && result?.billingEntities[0]?.address) {
                result.billingEntities[0].address.hasPoBox = Boolean(result.billingEntities[0].address.poBoxNumber);
            }

            this.editSupplier = result;
        });
    }

    @action
    createSupplier() {
        this.editSupplier = {
            id: '',
            logo: '',
            logoContent: null,
            name: '',
            isPublished: false,
            headerIntro: '',
            about: '',
            email: '',
            isAvailableByDefault: true,
            hasSubscriptionModel: false,
            requiresAdditionalData: false,
            canViewPersonalDetailsForOrders: false,
            canManageOwnSupply: false,
            billingEntities: [
                {
                    id: '',
                    name: '',
                    supplierId: '',
                    budgetType: BudgetType.Employee,
                    billingId: undefined,
                    invoiceEmail: '',
                    website: '',
                    shouldPayVat: true,
                    vatNumber: '',
                    cocNumber: '',
                    iban: '',
                    phoneNumber: '',
                    paymentCondition: PaymentCondition.Within14Days,
                    address: {
                        streetName: undefined,
                        houseNumber: undefined,
                        poBoxNumber: undefined,
                        postcode: '',
                        city: '',
                        country: 'Nederland',
                        hasPoBox: false,
                    },
                },
            ],
            externalFeedType: 0,
            externalDataFeed: '',
            IsExternalFeedScheduledRefreshEnabled: true,
        };
    }

    @action
    async getAll(pagedFilterAndSortedRequest: GetAllSuppliersInput) {
        const result = await SupplierService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.suppliers = result;
        });
    }

    @action
    async getAllPublic(pagedFilterAndSortedRequest: GetAllSuppliersInput) {
        const result = await SupplierService.getAllPublic(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.publicSuppliers = result;
        });
    }

    @action
    async getAllEmployerSuppliers() {
        const result = await SupplierService.getAllEmployerSuppliers();
        runInAction(() => {
            this.employerSuppliers = result;
        });
    }

    @action
    async createForEmployer(input: CreateForEmployerInput) {
        const result = await SupplierService.createForEmployer(input);
        runInAction(() => {
            this.employerSuppliers.items.push(result);
        });
    }

    @action
    async getNewBillingId(): Promise<number> {
        const result = await SupplierService.getAll({ maxResultCount: 9999, skipCount: 0 });

        const billingEntities = result.items
            .map((s) => s.billingEntities)
            .reduce((acc: SupplierBillingEntityDto[], s: SupplierBillingEntityDto[]) => acc.concat(s));

        return Math.max(...billingEntities.map((value) => (value.billingId !== undefined ? value.billingId : 0))) + 1;
    }

    //#region admin manage supply
    @action
    async getAllEmployerSettings(employerId: string, pagedFilterAndSortedRequest: GetAllSuppliersInput) {
        const result = await SupplierService.getEmployerSupplierSettings(employerId, pagedFilterAndSortedRequest);
        runInAction(() => {
            this.employerSupplierSettings = result;
        });
    }

    @action
    async updateEmployerSupplierSettings(employerId: string, input: UpdateSupplierEmployerSettingsInput[]) {
        await SupplierService.updateSupplierEmployerSettings(employerId, input);
    }
    //#endregion
}

export default SupplierStore;
