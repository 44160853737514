import { action, makeObservable, observable, runInAction } from 'mobx';
import TenantModel from 'src/models/Tenants/TenantModel';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { PagedTenantResultRequestDto } from 'src/services/tenant/dto/PagedTenantResultRequestDto';
import CreateTenantInput from 'src/services/tenant/dto/createTenantInput';
import { GetAllTenantOutput } from 'src/services/tenant/dto/getAllTenantOutput';
import type UpdateTenantInput from 'src/services/tenant/dto/updateTenantInput';
import tenantService from 'src/services/tenant/tenantService';

class TenantStore {
    @observable tenants: PagedResultDto<GetAllTenantOutput>;
    @observable tenantModel: TenantModel = new TenantModel();

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createTenantInput: CreateTenantInput) {
        await tenantService.create(createTenantInput);
    }

    @action
    createTenant() {
        this.tenantModel = {
            id: 0,
            isActive: true,
            name: '',
            tenancyName: '',
        };
    }

    @action
    async update(updateTenantInput: UpdateTenantInput) {
        const result = await tenantService.update(updateTenantInput);

        runInAction(() => {
            this.tenants.items = this.tenants.items.map((x: GetAllTenantOutput) => {
                if (x.id === updateTenantInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async delete(entityDto: EntityDto) {
        await tenantService.delete(entityDto);
        runInAction(() => {
            this.tenants.items = this.tenants.items.filter((x: GetAllTenantOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto) {
        const result = await tenantService.get(entityDto);
        runInAction(() => {
            this.tenantModel = result;
        });
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedTenantResultRequestDto) {
        const result = await tenantService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.tenants = result;
        });
    }
}

export default TenantStore;
