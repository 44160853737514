import 'es6-promise';
import 'isomorphic-fetch';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Router from 'src/components/Router';
import SessionStore from './stores/sessionStore';
import Stores from './stores/storeIdentifier';

export interface IAppProps {
    sessionStore?: SessionStore;
}

const App = inject(Stores.SessionStore)(
    observer(({ sessionStore }: IAppProps) => {
        useEffect(() => {
            if (
                sessionStore &&
                !window.location.pathname.startsWith('/p/') &&
                !window.location.pathname.startsWith('/user/')
            ) {
                sessionStore.getCurrentLoginInformations();
            }
        }, [sessionStore]);

        return <Router />;
    })
);

export default App;
