import http from '../httpService';
import { GetPageOutput } from './dto/getPageOutput';
import { PagedResultDto } from '../dto/pagedResultDto';
import { EntityDto } from '../dto/entityDto';
import { GetPageByIdentifierInput } from './dto/getPageByIdentifierInput';
import { PagedFilterAndSortedRequest } from '../dto/pagedFilterAndSortedRequest';

class PageService {
    public async create(createPageInput: GetPageOutput) {
        const result = await http.post('api/services/app/Page/Create', createPageInput);
        return result.data.result;
    }

    public async update(updatePageInput: GetPageOutput) {
        const result = await http.put('api/services/app/Page/Update', updatePageInput);
        return result.data.result;
    }

    public async delete(entityDto: EntityDto<string>) {
        const result = await http.delete('api/services/app/Page/Delete', { params: entityDto });
        return result.data;
    }

    public async get(entityDto: EntityDto<string>): Promise<GetPageOutput> {
        const result = await http.get('api/services/app/Page/Get', { params: entityDto });
        return result.data.result;
    }

    public async getByIdentifier(input: GetPageByIdentifierInput): Promise<GetPageOutput> {
        const result = await http.get('api/services/app/Page/GetByIdentifier', { params: input });
        return result.data.result;
    }

    public async getAll(
        pagedFilterAndSortedRequest: PagedFilterAndSortedRequest
    ): Promise<PagedResultDto<GetPageOutput>> {
        const result = await http.get('api/services/app/Page/GetAll', { params: pagedFilterAndSortedRequest });
        return result.data.result;
    }
}

export default new PageService();
