import { action, makeObservable, observable, runInAction } from 'mobx';
import { EmployerFilterType } from 'src/models/Filter/employerFilterType';
import TreeDataItem from 'src/models/treedataItem';
import { EmployerFilterDto } from 'src/services/employerFilter/dto/employerFilterDto';
import type EmployerFiltersDto from 'src/services/employerFilter/dto/employerFiltersDto';
import employerFilterService from 'src/services/employerFilter/employerFilterService';

class EmployerFilterStore {
    @observable employerFilters: EmployerFiltersDto;
    @observable activeEmployerFilterTreeData: TreeDataItem[];

    constructor() {
        makeObservable(this);
    }

    @action
    async getFilters() {
        const result = await employerFilterService.getFilters();
        runInAction(() => {
            this.employerFilters = result;
            this.activeEmployerFilterTreeData = this.getSelectData(this.employerFilters.activeItems);
        });
    }

    getSelectData = (children: EmployerFilterDto[]): TreeDataItem[] => {
        return (
            children
                ?.filter((e) => e.type == EmployerFilterType.Employer || e.children?.length > 0)
                .map((e) => ({
                    title: e.name,
                    value: e.id.toString(),
                    selectable: e.type == EmployerFilterType.Employer,
                    children: this.getSelectData(e.children),
                })) ?? []
        );
    };
}

export default EmployerFilterStore;
