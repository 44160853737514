import { action, makeObservable, observable, runInAction } from 'mobx';
import LoginModel from 'src/models/Login/loginModel';
import LoginResult from 'src/models/Login/loginResult';
import LoginTarget from 'src/models/Login/loginTarget';
import type { TwoFactorAuthenticationModel } from 'src/services/tokenAuth/dto/twoFactorAuthenticationModel';
import tokenAuthService from 'src/services/tokenAuth/tokenAuthService';
import tokenService from 'src/services/tokenService';

class AuthenticationStore {
    @observable loginModel: LoginModel = new LoginModel();

    @observable twoFactorEmailAddress: string;

    constructor() {
        makeObservable(this);
    }

    get isAuthenticated(): boolean {
        if (!abp.session.userId) return false;

        return true;
    }

    @action
    public async login(model: LoginModel): Promise<LoginResult> {
        runInAction(() => {
            this.loginModel = model;
        });

        const result = await tokenAuthService.authenticate({
            userNameOrEmailAddress: model.userNameOrEmailAddress,
            password: model.password,
            deviceIdentifier: '',
            rememberClient: model.rememberMe,
            target: LoginTarget.Admin,
        });

        if (!result.validRequest && result.reason === 0) {
            return LoginResult.EmployeeLoginAttempt;
        }

        if (result.twoFactor !== null && result.twoFactor.required) {
            runInAction(() => {
                this.twoFactorEmailAddress = result.twoFactor.emailAddress;
            });
            return LoginResult.RequiresTwoFactor;
        }

        tokenService.setToken(
            result.accessToken,
            result.refreshToken,
            model.rememberMe ? result.expireInSeconds : undefined
        );

        return LoginResult.Success;
    }

    @action
    public async logout(): Promise<boolean> {
        return tokenAuthService.logout().then((success) => {
            if (success) {
                localStorage.clear();
                sessionStorage.clear();
                tokenService.clear();
            }

            return success;
        });
    }

    @action
    public async twoFactorAuthenticate(input: TwoFactorAuthenticationModel): Promise<LoginResult> {
        const result = await tokenAuthService.twoFactorAuthenticate(input);

        tokenService.setToken(
            result.accessToken,
            result.refreshToken,
            input.rememberClient ? result.expireInSeconds : undefined
        );

        return LoginResult.Success;
    }

    @action
    public async impersonateUser(userId: number): Promise<LoginResult> {
        const result = await tokenAuthService.impersonateUser({ userId });

        tokenService.setToken(result.accessToken, result.refreshToken, result.expireInSeconds);

        return LoginResult.Success;
    }
}
export default AuthenticationStore;
