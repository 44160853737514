import http from '../httpService';
import { GetAllSuggestionsOutput } from './dto/getAllSuggestionsOutput';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import { GetAllSuggestionsInput } from './dto/getAllSuggestionsInput';

class SuggestionService {
  public async getAll(pagedFilterAndSortedRequest: GetAllSuggestionsInput): Promise<PagedResultDto<GetAllSuggestionsOutput>> {
    const result = await http.get('api/services/app/Suggestion/Get', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }
}

export default new SuggestionService();
