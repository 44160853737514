import { action, makeObservable, observable, runInAction } from 'mobx';

import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { GetAllSuggestionsInput } from 'src/services/suggestion/dto/getAllSuggestionsInput';
import { GetAllSuggestionsOutput } from 'src/services/suggestion/dto/getAllSuggestionsOutput';
import SuggestionService from 'src/services/suggestion/suggestionService';

class SuggestionStore {
    @observable suggestions: PagedResultDto<GetAllSuggestionsOutput>;

    constructor() {
        makeObservable(this);
    }

    @action
    async getAll(pagedFilterAndSortedRequest: GetAllSuggestionsInput) {
        const result = await SuggestionService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.suggestions = result;
        });
    }
}

export default SuggestionStore;
