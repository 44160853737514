import { Guid } from 'guid-typescript';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { FaqCreateOrUpdateCategoryInput, GetFaqCategoryOutput } from 'src/services/faq/dto/faqCategoryDto';
import type { FaqCreateOrUpdateQuestionInput, GetFaqQuestionOutput } from 'src/services/faq/dto/faqQuestionDto';
import faqService from 'src/services/faq/faqEntityService';

class FaqStore {
    @observable faqCategories: PagedResultDto<GetFaqCategoryOutput>;
    @observable faqQuestions: PagedResultDto<GetFaqQuestionOutput>;
    @observable editFaqCategory: FaqCreateOrUpdateCategoryInput;
    @observable editFaqQuestion: FaqCreateOrUpdateQuestionInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async getAllCategories(input: { maxResultCount: number; skipCount: number }) {
        const result = await faqService.getAllCategories(input);
        runInAction(() => {
            this.faqCategories = result;
        });
    }

    @action
    async getCategoryById(entityDto: EntityDto) {
        const result = await faqService.getCategoryById(entityDto);
        runInAction(() => {
            this.editFaqCategory = result;
        });
    }

    @action
    async createCategory(createCategoryInput: FaqCreateOrUpdateCategoryInput) {
        const result = await faqService.createCategory(createCategoryInput);
        runInAction(() => {
            this.faqCategories.items.push(result);
        });
    }

    @action
    createInitCategory() {
        this.editFaqCategory = {
            localizations: [
                {
                    id: undefined,
                    parentId: 0,
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    description: '',
                },
            ],
            order: 0,
            id: 0,
        };
    }
    @action
    async updateCategory(updateCategoryInput: FaqCreateOrUpdateCategoryInput) {
        const result = await faqService.updateCategory(updateCategoryInput);
        runInAction(() => {
            this.faqCategories.items = this.faqCategories.items.map((x: GetFaqCategoryOutput) => {
                if (x.id === updateCategoryInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async deleteCategory(entityDto: EntityDto) {
        await faqService.deleteCategory(entityDto);
        runInAction(() => {
            this.faqCategories.items = this.faqCategories.items.filter(
                (x: GetFaqCategoryOutput) => x.id !== entityDto.id
            );
        });
    }

    @action
    createInitQuestion() {
        this.editFaqQuestion = {
            localizations: [
                {
                    id: undefined,
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    parentId: Guid.createEmpty(),
                    localeId: 'nl',
                    answer: '',
                    question: '',
                },
            ],
            order: 0,
            id: Guid.createEmpty(),
        };
    }

    @action
    async getAllFaq(category?: string) {
        await faqService.getAllFaq(category);
    }

    @action
    async getAllFaqAnonymous(category?: string) {
        await faqService.getAllFaqAnonymous(category);
    }

    @action
    async getAllQuestions(input: { maxResultCount: number; skipCount: number }) {
        const result = await faqService.getAllQuestions(input);
        runInAction(() => {
            this.faqQuestions = result;
        });
    }

    @action
    async getQuestionById(entityDto: EntityDto<Guid>) {
        const result = await faqService.getQuestionById(entityDto);
        runInAction(() => {
            this.editFaqQuestion = result;
        });
    }

    @action
    async createQuestion(createQuestionInput: FaqCreateOrUpdateQuestionInput) {
        const result = await faqService.createQuestion(createQuestionInput);
        runInAction(() => {
            this.faqQuestions.items.push(result);
        });
    }

    @action
    async updateQuestion(updateQuestionInput: FaqCreateOrUpdateQuestionInput) {
        const result = await faqService.updateQuestion(updateQuestionInput);
        runInAction(() => {
            this.faqQuestions.items = this.faqQuestions.items.map((x: GetFaqQuestionOutput) => {
                if (x.id === updateQuestionInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async deleteQuestion(entityDto: EntityDto<Guid>) {
        await faqService.deleteQuestion(entityDto);
        runInAction(() => {
            this.faqQuestions.items = this.faqQuestions.items.filter(
                (x: GetFaqQuestionOutput) => x.id !== entityDto.id
            );
        });
    }
}

export default FaqStore;
