export enum OrderStatus {
    Unknown,
    Placed,
    Confirmed,
    Done,
    Canceled,
    ApprovalPending,
    ApprovalDenied,
    ApprovalExpired,
    AdditionalDataPending,
    AdditionalDataExpired,
    StapBudgetPending,
}

export enum OrderStatusCreateOrUpdate {
    Placed = 1,
    Confirmed,
    Done,
    Canceled,
}

export enum OrderStatusFilter {
    Placed = 1,
    Confirmed,
    Done,
    Canceled,
    ApprovalPending,
    ApprovalDenied,
    ApprovalExpired,
    AdditionalDataPending,
    AdditionalDataExpired,
    StapBudgetPending,
}
