export default class Stores {
    static AccountStore: string = 'accountStore';
    static ApiUserStore: string = 'apiUserStore';
    static ArticleStore: string = 'articleStore';
    static AuthenticationStore: string = 'authenticationStore';
    static BudgetStore: string = 'budgetStore';
    static BillingEntityStore: string = 'billingEntityStore';
    static CarouselStore: string = 'carouselStore';
    static CategoryStore: string = 'categoryStore';
    static DashboardStore: string = 'dashboardStore';
    static FeaturedInterventionStore: string = 'featuredInterventionStore';
    static EmployerFilterStore: string = 'employerFilterStore';
    static EmployerGroupStore: string = 'employerGroupStore';
    static InterventionChangeRequestStore: string = 'interventionChangeRequestStore';
    static ExternalInterventionStore: string = 'externalInterventionStore';
    static InterventionStore: string = 'interventionStore';
    static InterventionThemeStore: string = 'interventionThemeStore';
    static OrderStore: string = 'orderStore';
    static FeedbackStore: string = 'feedbackStore';
    static PageStore: string = 'pageStore';
    static PushNotificationStore: string = 'pushNotificationStore';
    static QuestionStore: string = 'questionStore';
    static RoleStore: string = 'roleStore';
    static SessionStore: string = 'sessionStore';
    static SuggestionStore: string = 'suggestionStore';
    static SupplierStore: string = 'supplierStore';
    static SurveyStore: string = 'surveyStore';
    static SurveySubmissionStore: string = 'surveySubmissionStore';
    static TaxWageSurveyStore: string = 'taxWageSurveyStore';
    static TenantStore: string = 'tenantStore';
    static ThemeStore: string = 'themeStore';
    static UserProfileStore: string = 'userProfileStore';
    static UserStore: string = 'userStore';
    static FaqStore: string = 'faqStore';
}
