import ApplicationInfoDto from './applicationInfoDto';
import EmployerLoginInfoDto from './employerLoginInfoDto';
import UserLoginInfoDto from './userLoginInfoDto';
import TenantLoginInfoDto from './tenantLoginInfoDto';
import SupplierLoginInfoDto from './supplierLoginInfoDto';
import ManagerLoginInfoDto from './managerLoginInfoDto';

export class GetCurrentLoginInformations {
    application: ApplicationInfoDto;
    employer: EmployerLoginInfoDto;
    supplier: SupplierLoginInfoDto;
    manager: ManagerLoginInfoDto;
    user: UserLoginInfoDto;
    tenant: TenantLoginInfoDto;
}
