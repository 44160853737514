import { action, makeObservable, observable, runInAction } from 'mobx';
import { BudgetType } from 'src/models/Interventions/BudgetType';
import { OrderStatus } from 'src/models/Orders/OrderStatus';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateOrderInput } from 'src/services/order/dto/CreateOrUpdateOrderInput';
import type { GetAllOrdersInput } from 'src/services/order/dto/getAllOrdersInput';
import { GetAllOrdersOutput } from 'src/services/order/dto/getAllOrdersOutput';
import { GetOrderOutput } from 'src/services/order/dto/getOrderOutput';
import { GetOrderRequestOutput } from 'src/services/order/dto/getOrderRequestOutput';
import OrderService from 'src/services/order/orderService';
import type {
    OrderFeedbackDoneInput,
    OrderFeedbackNotStartedInput,
    OrderFeedbackStartedInput,
} from '../services/feedback/dto/FeedbackInput';
import type { BaseFeedbackInput } from './../services/feedback/dto/FeedbackInput';

class OrderStore {
    @observable orders: PagedResultDto<GetOrderOutput>;
    @observable orderRequests: PagedResultDto<GetOrderRequestOutput>;
    @observable employeeOrders: PagedResultDto<GetAllOrdersOutput>;
    @observable editOrder: CreateOrUpdateOrderInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async create(createOrderInput: CreateOrUpdateOrderInput) {
        const result = await OrderService.create(createOrderInput);
        runInAction(() => {
            this.orders.items.push(result);
        });
    }

    @action
    async update(updateOrderInput: CreateOrUpdateOrderInput) {
        const result = await OrderService.update(updateOrderInput);
        runInAction(() => {
            this.orders.items = this.orders.items.map((x: GetOrderOutput) => {
                if (x.id === updateOrderInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async approve(orderId: string, approved: boolean) {
        const result = await OrderService.approve({
            orderId,
            approved,
        });

        runInAction(() => {
            if (this.orders?.items) {
                this.orders.items = this.orders.items.map((x: GetOrderOutput) => {
                    if (x.id === orderId) x = result;
                    return x;
                });
            }

            if (this.orderRequests?.items) {
                this.orderRequests.items = this.orderRequests.items.filter(
                    (x: GetOrderRequestOutput) => x.id !== orderId
                );
            }
        });
    }

    @action
    async delete(entityDto: EntityDto<string>) {
        await OrderService.delete(entityDto);
        runInAction(() => {
            this.orders.items = this.orders.items.filter((x: GetOrderOutput) => x.id !== entityDto.id);
        });
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await OrderService.get(entityDto);
        runInAction(() => {
            result.costLines = result.costLines.sort((a, b) => a.lineNumber - b.lineNumber);
            this.editOrder = result;
        });
    }

    @action
    async getAllOrderRequests(input: PagedFilterAndSortedRequest) {
        const result = await OrderService.getAllOrderRequests(input);
        runInAction(() => {
            this.orderRequests = result;
        });
    }

    @action
    createOrder() {
        this.editOrder = {
            creationTime: undefined,
            employeeId: '',
            interventionId: '',
            orderStatus: OrderStatus.Placed,
            confirmationDate: undefined,
            id: '',
            startDate: undefined,
            endDate: undefined,
            costLines: [],
            budgetTypes: BudgetType.Employee,
            additionalPersonalInfo: {
                name: '',
                surname: '',
                phoneNumber: undefined,
                dateOfBirth: undefined,
                address: {
                    streetName: '',
                    houseNumber: undefined,
                    addition: undefined,
                    postcode: '',
                    city: '',
                    country: 'Nederland',
                    hasPoBox: false,
                },
            },
        };
    }

    @action
    async getAll(input: GetAllOrdersInput) {
        const result = await OrderService.getAll(input);
        runInAction(() => {
            this.orders = result;
        });
    }

    @action
    async submitFeedbackNotStarted(input: OrderFeedbackNotStartedInput) {
        const result = await OrderService.submitFeedbackNotStarted(input);
        runInAction(() => {
            this.orders = result;
        });
    }

    @action
    async submitFeedbackStarted(input: OrderFeedbackStartedInput) {
        const result = await OrderService.submitFeedbackStarted(input);
        runInAction(() => {
            this.orders = result;
        });
    }

    @action
    async submitFeedbackDone(input: OrderFeedbackDoneInput) {
        const result = await OrderService.submitFeedbackDone(input);
        runInAction(() => {
            this.orders = result;
        });
    }

    @action
    async submitFeedbackOpen(input: BaseFeedbackInput) {
        const result = await OrderService.submitOrderFeedbackOpen(input);
        runInAction(() => {
            this.orders = result;
        });
    }
}

export default OrderStore;
